<script setup>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { VForm } from "vuetify/components/VForm";
import { requiredValidator } from "@core/utils/validators.js";
import { ref, watch, computed, nextTick } from "vue";
import { useAccessControl } from "@/composables/useAccessControl";

definePage({
    meta: {
        permission: "write_calendar",
    },
});

const props = defineProps({
    isDrawerOpen: {
        type: Boolean,
        required: true,
    },
    event: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits([
    "update:isDrawerOpen",
    "addEvent",
    "updateEvent",
    "removeEvent",
]);

const { canAccess } = useAccessControl();
const refForm = ref();
const event = ref({});
const showFields = ref(true);

const resetEvent = () => {
    event.value = JSON.parse(JSON.stringify(props.event));
    event.value.extendedProps = event.value.extendedProps || {};
    event.value.allDay = event.value.allDay || false;
    event.value.sameDay =
        event.value.extendedProps.start_date &&
        !event.value.extendedProps.end_date;

    nextTick(() => {
        refForm.value?.resetValidation();
    });
};

watch(
    () => props.isDrawerOpen,
    (newVal) => {
        if (newVal) {
            showFields.value = true;
            resetEvent();
        } else {
            setTimeout(() => {
                showFields.value = false;
                event.value = {};
            }, 300);
        }
    }
);

watch(
    () => props.event,
    (newVal) => {
        if (newVal && props.isDrawerOpen) {
            resetEvent();
        }
    },
    { deep: true }
);

const removeEvent = () => {
    emit("removeEvent", String(event.value.id));
    emit("update:isDrawerOpen", false);
};

const handleSubmit = () => {
    console.log("Submitting form. Current event state:", event.value);
    refForm.value?.validate().then(({ valid, errors }) => {
        console.log("Validation result:", valid);
        console.log("Validation errors:", errors);

        if (valid) {
            const eventToSubmit = { ...event.value };

            if (eventType.value === "Event") {
                if (eventToSubmit.allDay) {
                    delete eventToSubmit.extendedProps.start_time;
                    delete eventToSubmit.extendedProps.end_time;
                }
                if (eventToSubmit.sameDay) {
                    delete eventToSubmit.extendedProps.end_date;
                    delete eventToSubmit.extendedProps.end_time;
                }
            }

            console.log("Submitting event:", eventToSubmit);

            if ("id" in eventToSubmit) emit("updateEvent", eventToSubmit);
            else emit("addEvent", eventToSubmit);

            emit("update:isDrawerOpen", false);
        } else {
            console.log("Form validation failed. Please check the fields.");
        }
    });
};

const onCancel = () => {
    emit("update:isDrawerOpen", false);
};

const dialogModelValueUpdate = (val) => {
    emit("update:isDrawerOpen", val);
};

const datePickerConfig = computed(() => ({
    dateFormat: "dd/MM/yyyy",
    firstDayOfWeek: 1,
}));

const timePickerConfig = computed(() => ({
    color: "primary",
    format: "24hr",
}));

const eventType = computed(() => {
    console.log(
        "Current event type:",
        event.value?.type || event.value?.extendedProps?.type || ""
    );
    return event.value?.type || event.value?.extendedProps?.type || "";
});

const isAppointmentOrEvent = computed(() =>
    ["Appointment", "Event"].includes(eventType.value)
);

const isEvent = computed(() => eventType.value === "Event");

const isSimpleEvent = computed(() =>
    ["Appointment", "Consultation", "Intervention", "Control"].includes(
        eventType.value
    )
);

const showTimePickers = computed(() => {
    return (
        (isEvent.value && !event.value.allDay) ||
        eventType.value === "Appointment"
    );
});

const showEndDate = computed(() => isEvent.value && !event.value.sameDay);

const titleRules = computed(() => {
    return isEvent.value ? [requiredValidator] : [];
});

const dateRules = computed(() => [requiredValidator]);

const timeRules = computed(() => {
    if (
        (eventType.value === "Appointment" ||
            (isEvent.value && !event.value.allDay)) &&
        !event.value.sameDay
    ) {
        return [requiredValidator];
    }
    return [];
});
</script>

<template>
    <VNavigationDrawer
        temporary
        location="end"
        :model-value="props.isDrawerOpen"
        width="420"
        class="scrollable-content"
        @update:model-value="dialogModelValueUpdate"
    >
        <AppDrawerHeaderSection
            :title="
                (event.id ? $t('Update') : $t('Add')) +
                ' ' +
                $t(event?.extendedProps?.type || 'Event')
            "
            @cancel="$emit('update:isDrawerOpen', false)"
        >
            <template
                v-if="
                    event?.extendedProps?.type === 'Event' &&
                    canAccess.byPermission('delete_calendar')
                "
                #beforeClose
            >
                <IconBtn
                    :disabled="eventType === 'Consultation'"
                    v-show="event.id"
                    @click="removeEvent"
                >
                    <VIcon size="18" icon="tabler-trash" />
                </IconBtn>
            </template>
        </AppDrawerHeaderSection>

        <PerfectScrollbar :options="{ wheelPropagation: false }">
            <VCard flat>
                <VCardText>
                    <VForm ref="refForm" @submit.prevent="handleSubmit">
                        <VRow v-if="showFields">
                            <VCol cols="12">
                                <AppTextField
                                    v-model="event.title"
                                    :label="$t('Title')"
                                    :rules="titleRules"
                                    :disabled="isSimpleEvent"
                                />
                            </VCol>

                            <VCol cols="12">
                                <AppTextField
                                    readonly
                                    :placeholder="
                                        eventType ? $t(eventType) : ''
                                    "
                                    :label="$t('Label')"
                                />
                            </VCol>

                            <VCol v-if="isEvent || isSimpleEvent" cols="12">
                                <DatePicker
                                    v-model="event.extendedProps.start_date"
                                    :label="
                                        isEvent ? $t('Start Date') : $t('Date')
                                    "
                                    placeholder="DD/MM/YYYY"
                                    :config="datePickerConfig"
                                    :rules="dateRules"
                                />
                            </VCol>

                            <VCol v-if="showTimePickers" cols="12">
                                <TimePicker
                                    v-model="event.extendedProps.start_time"
                                    :label="$t('Start Time')"
                                    placeholder="HH:MM"
                                    :config="timePickerConfig"
                                    :rules="timeRules"
                                />
                            </VCol>

                            <VCol v-if="showEndDate" cols="12">
                                <DatePicker
                                    v-model="event.extendedProps.end_date"
                                    :label="$t('End Date')"
                                    placeholder="DD/MM/YYYY"
                                    :config="datePickerConfig"
                                    :rules="dateRules"
                                />
                            </VCol>

                            <VCol
                                v-if="showTimePickers && !event.sameDay"
                                cols="12"
                            >
                                <TimePicker
                                    v-model="event.extendedProps.end_time"
                                    :label="$t('End Time')"
                                    placeholder="HH:MM"
                                    :config="timePickerConfig"
                                    :rules="timeRules"
                                />
                            </VCol>

                            <VCol
                                v-if="isEvent"
                                cols="12"
                                class="d-flex justify-center"
                            >
                                <div class="d-flex flex-column gap-4">
                                    <VSwitch
                                        v-model="event.allDay"
                                        :label="$t('All Day')"
                                    />
                                    <VSwitch
                                        v-model="event.sameDay"
                                        :label="$t('Same Day')"
                                    />
                                </div>
                            </VCol>

                            <VCol cols="12" class="d-flex justify-center">
                                <VBtn type="submit" class="me-3" :width="120">
                                    {{ $t("Save") }}
                                </VBtn>
                                <VBtn
                                    variant="outlined"
                                    color="secondary"
                                    @click="onCancel"
                                    :width="120"
                                >
                                    {{ $t("Cancel") }}
                                </VBtn>
                            </VCol>
                        </VRow>
                    </VForm>
                </VCardText>
            </VCard>
        </PerfectScrollbar>
    </VNavigationDrawer>
</template>

<style scoped>
.scrollable-content {
    overflow-y: auto;
}
</style>
