{
    "seo": {
        "title": "MedSyn Solutions - Logiciel médical intelligent",
        "description": "MedSyn offre un logiciel complet propulsé par l'IA pour une gestion efficace des patients et une amélioration des soins de santé."
    },
    "nav": {
        "home": "Accueil",
        "specialties": "Spécialités",
        "features": "Fonctionnalités",
        "technology": "Technologie",
        "pricing": "Tarifs",
        "faq": "FAQ",
        "contact": "Contact"
    },
    "2": "2",
    "3": "3",
    "5": "5",
    "10": "10",
    "20": "20",
    "25": "25",
    "50": "50",
    "100": "100",
    "Features": "Fonctionnalités",
    "Revolutionize Your Medical Practice with MedSyn": "Révolutionnez votre pratique médicale avec MedSyn",
    "heroText": "Un logiciel complet, propulsé par l'IA, pour une gestion efficace des patients et une amélioration des soins de santé",
    "Demo Request": "Demande de Démo",
    "Fill out the form below to request a demo of MedSyn.": "Remplissez le formulaire ci-dessous pour demander une démo de MedSyn.",
    "Submit Request": "Envoyer la demande",
    "Tailored for Your Expertise": "Adapté à votre expertise",
    "Specialized solutions for diverse medical fields": "Des solutions spécialisées pour divers domaines médicaux",
    "Pediatric Surgery": "Chirurgie Pédiatrique",
    "Providing specialized care for infants, children, and adolescents with a range of surgical needs.": "Offrir des soins spécialisés aux nourrissons, enfants et adolescents ayant des besoins chirurgicaux divers.",
    "General Surgery": "Chirurgie Générale",
    "Comprehensive surgical solutions addressing a broad spectrum of conditions.": "Des solutions chirurgicales complètes pour un large éventail de pathologies.",
    "Cosmetic Surgery": "Chirurgie Esthétique",
    "Advanced cosmetic procedures to enhance and refine your natural beauty.": "Des procédures esthétiques avancées pour sublimer et affiner votre beauté naturelle.",
    "Core Features": "Fonctionnalités clés",
    "Empower Your Practice": "Optimisez votre pratique",
    "Comprehensive tools to streamline your workflow": "Des outils complets pour rationaliser votre flux de travail",
    "Manage patient records, consultations, interventions, controls, and medical files.": "Gérer les dossiers des patients, les consultations, les interventions, les contrôles et les dossiers médicaux.",
    "Efficiently schedule and manage patient appointments with an intuitive interface.": "Planifiez et gérez efficacement les rendez-vous avec une interface intuitive.",
    "Document Printing": "Impression de documents",
    "Easily create and print all sort of medical documents prefilled with your patient's data.": "Créez et imprimez facilement toutes sortes de documents médicaux préremplis avec les données de vos patients.",
    "Extensive Medical Database": "Base de données médicale étendue",
    "Access and manage an extensive medical database tailored to your specialty.": "Accédez et gérez une base de données médicale étendue adaptée à votre spécialité.",
    "Advanced Statistics": "Statistiques avancées",
    "Gain valuable insights into your practice with detailed analytics and reporting tools.": "Obtenez des informations précieuses sur votre pratique avec des outils d'analyse et de rapport détaillés.",
    "Integrated Calendar": "Calendrier intégré",
    "Streamline your schedule with a comprehensive calendar system for interventions, consultations, appointments and other tasks.": "Optimisez votre planning avec un système de calendrier complet pour les interventions, consultations, rendez-vous et autres tâches.",
    "Efficiently manage multiple users with customizable roles and permissions.": "Gérez efficacement plusieurs utilisateurs avec des rôles et des autorisations personnalisables.",
    "Billing Management": "Gestion de la facturation",
    "Simplify your financial operations with integrated billing and invoicing features.": "Simplifiez vos opérations financières avec des fonctionnalités de facturation et d’émission de factures intégrées.",
    "Intelligent Chatbot": "Chatbot intelligent",
    "Get instant assistance and answers to your queries with our built-in chatbot.": "Obtenez une assistance instantanée et des réponses à vos questions avec notre chatbot intégré.",
    "Technology": "Technologie",
    "Advancing Medical Practice": "Faire progresser la pratique médicale",
    "AI-driven tools, robust security, and seamless multilingual support": "Outils propulsés par l'IA, sécurité renforcée, et support multilingue fluide",
    "AI-Powered": "Propulsé par l'IA",
    "Leverage cutting-edge AI for automatic report generation, speech-to-EHR functionality, and intelligent diagnostic suggestions.": "Exploitez l'IA de pointe pour la génération automatique de rapports, la transcription vocale vers DME, et des suggestions diagnostiques intelligentes.",
    "Secure & Compliant": "Sécurisé et conforme",
    "HIPAA-compliant multi-tenant architecture ensures the highest level of data security and privacy for your practice and patients.": "Une architecture multi-locataires conforme aux normes HIPAA assure le plus haut niveau de sécurité des données et de confidentialité pour votre pratique et vos patients.",
    "Multilingual Support": "Support multilingue",
    "Seamlessly switch between Arabic, French, and English to serve diverse patient populations and expand your practice reach.": "Basculez facilement entre l'arabe, le français et l'anglais pour servir une population de patients diversifiée et étendre la portée de votre pratique.",
    "Reviews": "Avis",
    "Trusted by Healthcare Professionals": "Reconnue par les professionnels de la santé",
    "What our users say about MedSyn Solutions": "Ce que disent nos utilisateurs à propos de MedSyn Solutions",
    "Choose Your Plan": "Choisissez votre plan",
    "Flexible options to suit your practice needs": "Des options flexibles pour répondre aux besoins de votre pratique",
    "Pay Monthly": "Paiement Mensuel",
    "Pay Annual": "Paiement Annuel",
    "Advanced": "Avancé",
    "Premium": "Premium",
    "Email Address": "Adresse Électronique",
    "Up to 500 patients annually": "Jusqu'à 500 patients par an",
    "Up to 5GB Storage": "Jusqu'à 5 Go de stockage",
    "Extra Users": "Utilisateurs supplémentaires",
    "AI tools access": "Accès aux outils IA",
    "Up to 1000 patients annually": "Jusqu'à 1000 patients par an",
    "Up to 20GB Storage": "Jusqu'à 20 Go de stockage",
    "2 Extra Users": "2 utilisateurs supplémentaires",
    "Limited AI tools access": "Accès limité aux outils IA",
    "Up to 2000 patients annually": "Jusqu'à 2000 patients par an",
    "Up to 50GB Storage": "Jusqu'à 50 Go de stockage",
    "5 Extra Users": "5 utilisateurs supplémentaires",
    "Full AI tools access": "Accès complet aux outils IA",
    "Frequently Asked Questions": "Questions Fréquentes",
    "Get answers to common queries about MedSyn Solutions": "Obtenez des réponses aux questions fréquentes sur MedSyn Solutions",
    "Q1": "Comment MedSyn assure-t-il la sécurité des données des patients?",
    "A1": "MedSyn utilise un chiffrement de pointe et respecte les directives HIPAA pour garantir la sécurité et la confidentialité des données des patients. Nous utilisons un stockage cloud sécurisé et fournissons un contrôle d'accès basé sur les rôles pour limiter l'exposition des données.",
    "Q2": "Quels types de spécialités médicales MedSyn Solutions prend-il en charge?",
    "A2": "MedSyn Solutions offre des interfaces spécialisées pour divers domaines médicaux, y compris la chirurgie pédiatrique, la chirurgie générale et la chirurgie esthétique. Notre plateforme est conçue pour s'adapter à différentes spécialités, avec des fonctionnalités personnalisables pour répondre aux besoins uniques de chaque pratique médicale.",
    "Q3": "Quel type de support MedSyn propose-t-il?",
    "A3": "MedSyn fournit une assistance technique 24h/24 et 7j/7 par téléphone, e-mail et chat en direct. Nous offrons également une intégration personnalisée, des sessions de formation régulières, et une base de connaissances complète pour vous assurer de tirer le meilleur parti de notre plateforme.",
    "Q4": "Est-il possible d'accéder à MedSyn depuis plusieurs appareils ou emplacements?",
    "A4": "Oui, MedSyn est une solution basée sur le cloud accessible de manière sécurisée depuis n'importe quel appareil connecté à Internet. Cela permet aux professionnels de la santé d'accéder aux informations des patients et de gérer leur pratique depuis plusieurs endroits, ce qui améliore la flexibilité et l'efficacité.",
    "Q5": "Existe-t-il une application mobile pour MedSyn?",
    "A5": "Actuellement, MedSyn est disponible en tant qu'application web accessible depuis n'importe quel appareil disposant d'un navigateur moderne. Nous développons activement une application mobile pour offrir encore plus de commodité et de flexibilité à nos utilisateurs. Restez à l'écoute pour les mises à jour sur sa sortie.",
    "Contact": "Contact",
    "Get in Touch": "Contactez-nous",
    "We're here to support your practice": "Nous sommes là pour soutenir votre pratique",
    "Send us a message": "Envoyez-nous un message",
    "ContactSubMsg": "Que vous ayez des questions sur les fonctionnalités, les essais, les tarifs ou autre, notre équipe est prête à répondre à toutes vos questions",
    "Request a Demo":"Demander une démonstration",
    "Sent": "Envoyée",
    "Your request has been successfully submitted.": "Votre demande a été soumise avec succès.",
    "Our team will contact you shortly to schedule your demo.": "Notre équipe vous contactera dans les plus brefs délais pour planifier votre démonstration.",
    "Tools": "Outils",
    "Chatbot": "Chatbot",
    "Start": "Démarrer",
    "Chat not activated": "Chat non activé",
    "Use the menu to start a chat": "Utilisez le menu pour démarrer une discussion",
    "No messages yet": "Pas encore de messages",
    "Start typing to begin the conversation": "Commencez à taper pour démarrer la conversation",
    "Type your message...":"Tapez votre message...",
    "Pediatric Surgeon": "Chirurgien Pédiatre",
    "General Surgeon": "Chirurgien Général",
    "Cosmetic Surgeon": "Chirurgien Esthétique",
    "MedSyn Access": "Accès MedSyn",
    "Step": "Étape",
    "Administrator": "Administrateur",
    "MedSyn ID": "ID MedSyn",
    "Community & Networking": "Communauté & Networking",
    "Platform": "Plateforme",
    "Feed": "Fil D'Actualité",
    "Welcome": "Bienvenu",
    "Dismiss": "Fermer",
    "Request timed out": "La requête a expiré",
    "Folder": "Dossier",
    "Patients": "Patients",
    "Doctors": "Médecins",
    "Medications": "Médicaments",
    "Diagnostics": "Diagnostics",
    "Techniques": "Techniques",
    "Consents": "Consentement",
    "Controls": "Contrôles",
    "Background": "Antécédents",
    "Medical Background": "Antécédents Médicaux",
    "Family Background": "Antécédents Familiaux",
    "Surgical Background": "Antécédents Chirurgicaux",
    "Allergic Background": "Antécédents Allergiques",
    "MED_BG": "Médicaux",
    "FAM_BG": "Familiaux",
    "SUR_BG":"Chirurgicaux",
    "ALE_BG":"Allergiques",
    "Control": "Contrôle",
    "Control List": "Liste des contrôles",
    "Create Control": "Créer un contrôle",
    "Edit Control": "Modifier le contrôle",
    "Control Date": "Date de contrôle",
    "Add Control": "Ajouter Contrôle",
    "Appointments": "Rendez-Vous",
    "Users": "Utilisateurs",
    "Visits": "Visites",
    "Operations": "Opérations",
    "Upcoming Events": "Prochains Événements",
    "Upcoming Appointments": "Prochains Rendez-Vous",
    "First Name": "Prénom",
    "Last Name": "Nom",
    "Age": "Âge",
    "Gender": "Genre",
    "Years": "Ans",
    "Months": "Mois",
    "Date": "Date",
    "Start Time": "Heure de début",
    "Select Start Time": "Sélectionner l'heure de début",
    "End Time": "Heure de fin",
    "At": "À",
    "View All": "Voir Tout",
    "Recent Patients": "Patients Récents",
    "Total Patients": "Patients Total",
    "Diagnosed Patients": "Patients Diagnostiqués",
    "Operated Patients": "Patients Opérés",
    "Pending Patients": "Patients En Attente",
    "Controlled Patients": "Controlled Patients",
    "Last week analytics": "Analyse de la semaine dernière",
    "Filters": "Filtres",
    "Filter": "Filtrer",
    "Patient List": "Liste des patients",
    "Deleted Patient List": "Liste des patients supprimés",
    "Doctor": "Médecin",
    "Client": "Client",
    "Diagnostic": "Diagnostic",
    "Technique": "Technique",
    "Last Diagnostics": "Dernier Diagnostics",
    "Last Techniques":"Dernières Techniques",
    "Add Patient": "Ajouter Patient",
    "SPEECH_EHR_INFO": "Cet outil vous permet d'enregistrer verbalement les informations sur les patients. Parlez clairement des détails du patient tels que son nom, sa date de naissance, son groupe sanguin, son sexe et ses antécédents médicaux ou familiaux.",
    "Start Recording": "Démarrer l'enregistrement",
    "Stop Recording": "Arrêter l'enregistrement",
    "Ready to record. Click 'Start Recording' when you're ready to speak.": "Prêt à enregistrer. Cliquez sur « Démarrer l'enregistrement » lorsque vous êtes prêt à parler.",
    "Recording... Speak clearly about the patient's information.": "Enregistrement... Parlez clairement des informations du patient.",
    "Error accessing microphone. Please ensure you've granted microphone permissions.": "Erreur d'accès au microphone. Veuillez vous assurer que vous avez accordé les autorisations relatives au microphone.",
    "Recording stopped. Processing...": "L'enregistrement s'est arrêté. Traitement...",
    "Couldn't extract any information. Please try again.": "Impossible d'extraire aucune information. Veuillez réessayer.",
    "Transcription successful.": "Transcription réussie.",
    "Extracted Data": "Données Extraites",
    "Generate Report": "Générer Rapport",
    "Download PDF Report": "Télécharger le rapport PDF",
    "Export": "Exporter",
    "Refresh": "Rafraîchir",
    "Search": "Recherche",
    "Parent": "Parent",
    "Upload Photo": "Télécharger une photo",
    "Upload": "Télécharger",
    "Reset": "Réinitialiser",
    "Allowed JPG, GIF or PNG. Max size of 800K": "JPG, GIF ou PNG autorisés. Taille maximale de 800K",
    "Male": "Masculin",
    "Female": "Féminin",
    "Blood Group": "Groupe Sanguin",
    "Prescriber": "Prescripteur",
    "Prescribers": "Prescripteurs",
    "Birth Date": "Date de Naissance",
    "Phone Number": "Numéro de téléphone",
    "Address": "Adresse",
    "City": "Ville",
    "Postal Code": "Code Postal",
    "Civil Status": "État Civil",
    "Single": "Célibataire",
    "Married": "Marié(e)",
    "Divorced": "Divorcé(e)",
    "Widowed": "Veuf (Veuve)",
    "Separated": "Séparé(e)",
    "Profession": "Profession",
    "Save": "Sauvegarder",
    "Next": "Suivant",
    "Finish": "Terminer",
    "Verify": "Vérifier",
    "Medical Folder": "Dossier Médical",
    "Consultations": "Consultations",
    "Interventions":"Interventions",
    "Follow Ups": "Fiches Médicales",
    "Media": "Médias",
    "Documents": "Documents",
    "PATIENT": "PATIENT",
    "PARENT": "PARENT",
    "Not Defined": "Non Défini",
    "Create Patient": "Créer Patient",
    "Edit Patient": "Modifier Patient",
    "Patient": "Patient",
    "Connect Patient": "Connecter Patient",
    "Connect": "Connecter",
    "Connected": "Connecté",
    "Previous": "Précédent",
    "Disease": "Pathologie",
    "Interventation": "Intervention",
    "Multimedia":"Multimédia",
    "Add Media": "Ajouter Média",
    "Item": "Élément",
    "Untitled": "Sans Titre",
    "File": "Fichier",
    "Billing": "Facturation",
    "Follow Up": "Fiche Médicale",
    "Time": "Heure",
    "Full Name": "Nom et Prénom",
    "Operator": "Opérateur",
    "Billed Person": "Personne Facturée",
    "Total Amount": "Montant Total",
    "Consultation Date": "Date Consultation",
    "Family History": "Antécédents Familiaux",
    "Medical History": "Antécédents Médicaux",
    "Disease History": "Histoire de la maladie",
    "Intervention Date": "Date Intervention",
    "Operative Techniques": "Techniques Opératoires",
    "Anesthetist": "Anesthésiste",
    "Operative Report": "Compte Rendu Opératoire",
    "Printing": "Impression",
    "Header": "Entête",
    "Normal Text": "Texte Normal",
    "Heading 1": "Titre 1",
    "Heading 2": "Titre 2",
    "Heading 3": "Titre 3",
    "Heading 4": "Titre 4",
    "Heading 5": "Titre 5",
    "Heading 6": "Titre 6",
    "Left": "Gauche",
    "Center": "Centre",
    "Right": "Droite",
    "Justify": "Justifier",
    "Black": "Noir",
    "Red": "Rouge",
    "Green": "Vert",
    "Blue": "Bleu",
    "Yellow": "Jaune",
    "Purple": "Violet",
    "Orange": "Orange",
    "Gray": "Gris",
    "Signature": "Signature",
    "Stamp": "Tampon",
    "Choose an Image (PNG, JPG, JPEG)": "Choisir une image (PNG, JPG, JPEG)",
    "Print": "Imprimer",
    "Generate": "Générer",
    "Preoperative Assessment": "Bilan Préopératoire",
    "Admission Letter": "Lettre d'Admission",
    "Date & Time": "Date & Heure",
    "Confidential Letter": "Lettre Confidentielle",
    "Consent Form": "Formulaire de Consentement",
    "Father": "Père",
    "Mother": "Mère",
    "Father Name": "Nom du père",
    "Mother Name": "Nom de la mère",
    "Consent": "Consentement",
    "Consultation": "Consultation",
    "Add Consultation": "Ajouter Consultation",
    "Consultation List": "Liste de consultation",
    "Create Consultation": "Créer une consultation",
    "Edit Consultation": "Modifier la consultation",
    "Create Intervention": "Créer une Intervention",
    "Edit Intervention": "Modifier L'Intervention",
    "Preoperative Consultation": "Consultation Préopératoire",
    "PreoperativeConsultation": "Consultation Préopératoire",
    "Doctor Letter": "Lettre Médecin",
    "Hospital Report": "Compte Rendu d'Hospitalisation",
    "Follow": "Suivi",
    "Medical Certificate": "Certificat Médical",
    "Number of days": "Nombre de jours",
    "Days": "Jours",
    "Select Parent": "Sélectionner Parent",
    "Select Media": "Sélectionner Médias",
    "Select follow up": "Sélectionner fiche médicale",
    "Medical Prescription": "Ordonnance Médicale",
    "Medication": "Médicament",
    "Select Medication": "Sélectionner Médicament",
    "Select date and time": "Sélectionner la date et l'heure",
    "Select Year": "Sélectionner Année",
    "Select consent": "Sélectionner consentement",
    "Close": "Fermer",
    "Transfer": "Transférer",
    "Download": "Télécharger",
    "All": "Tous",
    "Designation": "Désignation",
    "Department": "Département",
    "Add Doctor": "Ajouter Médecin",
    "Doctor List": "Liste des médecins",
    "Deleted Doctor List": "Liste des médecins supprimés",
    "Create Doctor": "Créer Médecin",
    "Edit Doctor": "Modifier Médecin",
    "Type": "Type",
    "Anesthesiologist": "Anesthésiste",
    "Other": "Autre",
    "Name": "Nom",
    "Dosage": "Dosage",
    "Frequency": "Fréquence",
    "Medication List": "Liste des médicaments",
    "Deleted Medication List": "Liste des médicaments supprimés",
    "Add Medication": "Ajouter Médicament",
    "Create Medication": "Créer Médicament",
    "Edit Medication": "Modifier Médicament",
    "Add Diagnostic": "Ajouter Diagnostic",
    "Diagnostic List": "Liste des diagnostics",
    "Deleted Diagnostic List": "Liste des diagnostics supprimés",
    "Edit Diagnostic": "Modifier Diagnostic",
    "Create Diagnostic": "Créer Diagnostic",
    "Technique List": "Liste des techniques",
    "Deleted Technique List": "Liste des techniques supprimées",
    "Add Technique": "Ajouter Technique",
    "Create Technique": "Créer Technique",
    "Edit Technique": "Modifier Technique",
    "Content": "Contenu",
    "Configuration": "Configuration",
    "Add Consent": "Ajouter Consentement",
    "Consent List": "Liste des consentement",
    "Deleted Consent List": "Liste de consentement supprimée",
    "Create Consent": "Créer Consentement",
    "Edit Consent": "Modifier Consentement",
    "Create Patient Consultation": "Créer une consultation de patient",
    "Edit Patient Consultation": "Modifier la consultation du patient",
    "Appointment List": "Liste des rendez-vous",
    "Deleted Appointment List": "Liste des rendez-vous supprimés",
    "Add Appointment": "Ajouter Rendez-Vous",
    "History": "Histoire",
    "From": "De",
    "To": "À",
    "Notes": "Notes",
    "Create Appointment": "Créer Rendez-Vous",
    "Edit Appointment": "Modifier Rendez-Vous",
    "Service": "Service",
    "Service List": "Liste des services",
    "Deleted Service List": "Liste des services supprimés",
    "Add Service": "Ajouter Service",
    "Create Service": "Créer Service",
    "Edit Service": "Modifier Service",
    "Base Price": "Prix de base",
    "Description": "Description",
    "Select Patient": "Sélectionner Patient",
    "Zip Code": "Code Postal",
    "Governorate": "Gouvernorat",
    "Select Governorate": "Sélectionner Gouvernorat",
    "Quantity is required": "La quantité est obligatoire",
    "Service is required for additional services": "Le service est obligatoire pour les services supplémentaires",
    "Service is required": "Le service est obligatoire",
    "Bill List": "Liste des notes d'honoraires",
    "Deleted Bill List": "Liste des notes d'honoraires supprimées",
    "Bill": "Note D'Honoraires",
    "Add Bill": "Ajouter Note D'Honoraires",
    "Create Bill": "Créer Note D'Honoraires",
    "Edit Bill": "Modifier Note D'Honoraires",
    "Bill Service": "Service de facturation",
    "Select Service": "Sélectionner un service",
    "Quantity": "Quantité",
    "Additional Amount": "Montant Supplémentaire",
    "User List": "Liste des utilisateurs",
    "Add User": "Ajouter Utilisateur",
    "Create User": "Créer Utilisateur",
    "Edit User": "Modifier Utilisateur",
    "Password Required": "Mot de passe requis",
    "Password": "Mot de passe",
    "Add Event": "Ajouter Événement",
    "Update Event": "Modifier Événement",
    "Title": "Titre",
    "Label": "Étiquette",
    "Select Date": "Sélectionner Date",
    "Start Date": "Date Début",
    "Select Start Date": "Sélectionner la date de début",
    "End Date": "Date Fin",
    "Select End Date": "Sélectionner la date de fin",
    "All Day": "Toute la journée",
    "Cancel": "Annuler",
    "Visit": "Visite",
    "Intervention": "Intervention",
    "Appointment": "Rendez-Vous",
    "Event": "Événement",
    "FILTER": "FILTRER",
    "View all": "Voir tous",
    "General": "Général",
    "Security": "Sécurité",
    "Mailing": "Courrier",
    "Shortcuts": "Raccourcis",
    "Details Updated Successfully": "Détails mis à jour avec succès",
    "Upload a new logo": "Télécharger un nouveau logo",
    "General Details": "Détails Généraux",
    "Profile Picture": "Photo de Profil",
    "Doctor Details": "Détails du médecin",
    "Doctor Details Updated Successfully": "Les coordonnées du médecin ont été mises à jour avec succès",
    "Minimum 8 characters long": "8 caractères minimum",
    "At least one lowercase character": "Au moins un caractère minuscule",
    "At least one number, symbol, or whitespace character": "Au moins un chiffre, un symbole ou un caractère d'espacement",
    "Password Updated Successfully": "Mot de passe mis à jour avec succès",
    "Current Password": "Mot de passe actuel",
    "New Password": "Nouveau mot de passe",
    "Confirm New Password": "Confirmer le nouveau mot de passe",
    "Password Requirements": "Exigences de mot de passe",
    "Change Password": "Modifier le mot de passe",
    "Mailing Updated Successfully": "Mailing mis à jour avec succès",
    "Host": "Hôte",
    "Port": "Port",
    "Printing Updated Successfully": "Impression mise à jour avec succès",
    "View your patient list": "Consultez votre liste de patients",
    "View your doctor list": "Consultez votre liste de médecins",
    "View your medication list": "Consultez votre liste de médicaments",
    "View your diagnostic list": "Consultez votre liste de diagnostiques",
    "View your technique list": "Consultez votre liste de techniques",
    "View your consent list": "Consultez votre liste de consentements",
    "View your appointment list": "Consultez votre liste de rendez-vous",
    "View your user list": "Consultez votre liste d'utilisateurs",
    "View your service list": "Consultez votre liste de services",
    "View your bill list": "Consultez votre liste de factures",
    "Create a new patient record": "Créez un nouveau patient",
    "Create a new doctor record": "Créez un nouveau médecin",
    "Create a new medication record": "Créez un nouveau médicament",
    "Create a new diagnostic record": "Créez un nouveau diagnostic",
    "Create a new technique record": "Créez une nouvelle technique",
    "Create a new consent record": "Créez un nouveau consentement",
    "Create a new appointment record": "Créez un nouveau rendez-vous",
    "Create a new user record": "Créez un nouvel utilisateur",
    "Create a new service record": "Créez un nouveau service",
    "Create a new bill record": "Créez une nouvelle facture",
    "List Patient": "Liste Patient",
    "List Doctor": "Liste Médecin",
    "List Medication": "Liste Médicament",
    "List Diagnostic": "Liste Diagnostique",
    "List Technique": "Liste Technique",
    "List Consent": "Liste Consentement",
    "List Appointment": "Liste Rendez-Vous",
    "List User": "Liste Utilisateur",
    "List Service": "Liste Service",
    "List Bill": "Liste Facture",
    "Try searching for": "Essayez de rechercher",
    "No Result For": "Aucun résultat pour",
    "system": "système",
    "light": "clair",
    "dark": "foncé",
    "English": "Anglais",
    "French": "Français",
    "Arabic": "Arabe",
    "Notifications": "Notifications",
    "Mark all as unread": "Marquer tout comme non lu",
    "Mark all as read": "Marquer tous comme lu",
    "No Notification Found": "Aucune notification trouvée",
    "View All Notifications": "Afficher toutes les notifications",
    "Support": "Support",
    "Logout": "Se Déconnecter",
    "Your journey to surgical innovation starts now.": "Votre voyage vers l'innovation chirurgicale commence maintenant.",
    "Current Plan": "Plan actuel",
    "Your Current Plan:": "Votre plan actuel:",
    "Plan": "Plan",
    "Essential": "Essentiel",
    "Professional": "Professionnel",
    "Ultimate": "Ultime",
    "Foundational access for healthcare professionals": "Accès fondamental pour les professionnels de la santé",
    "Active until": "Actif jusqu'au",
    "You'll receive an alert before your plan concludes to ensure uninterrupted access.": "Vous recevrez une alerte avant la fin de votre plan pour garantir un accès ininterrompu.",
    "100 TND Per Month": "100 TND Par Mois",
    "ofDays": "{day} de {days} jours",
    "remainingDays": "Il reste {days} jours avant la mise à jour de votre plan",
    "Popular": "Populaire",
    "Professional plan for individual practitioners and clinics": "Plan professionnel pour les praticiens individuels et les cliniques",
    "Shortcuts Updated Successfully": "Raccourcis mis à jour avec succès",
    "user": "utilisateur",
    "Log In": "Se Connecter",
    "Continue": "Continuer",
    "Back to login": "Retour à la connexion",
    "Remember me": "Se souvenir de moi",
    "Select doctor": "Sélectionner médecin",
    "Subject": "Sujet",
    "Message": "Message",
    "Send": "Envoyer",
    "Document Attached": "Document Joint",
    "A reset link has been sent": "Un lien de réinitialisation a été envoyé",
    "Password has been successfully reset": "Le mot de passe a été réinitialisé avec succès",
    "Password Confirmation": "Confirmation du mot de passe",
    "Confirm": "Confirmer",
    "Confirmation": "Confirmation",
    "Loading": "Chargement",
    "Password Updated": "Mot de passe mis à jour",
    "admin": "administrateur",
    "NoData": "Pas de données disponibles",
    "Choose Your Role": "Choisissez Votre Rôle",
    "Sign up as a medical professional": "S'inscrire en tant que professionnel de la santé",
    "Sign up as a patient": "S'inscrire en tant que patient",
    "Activation": "Activation",
    "Profile": "Profile",
    "License": "Licence",
    "License Key": "Clé de licence",
    "Access Code": "Code d'accès",
    "Verification": "Vérification",
    "Email Verification": "Vérifiez votre adresse électronique",
    "Account activation link sent to your email address": "Lien d'activation du compte envoyé à votre adresse électronique",
    "Verification link has been sent successfully": "Le lien de vérification a été envoyé avec succès",
    "Skip for now": "Ignorer pour le moment",
    "Didn't get the mail": "Vous n'avez pas reçu le courrier",
    "Resend": "Renvoyer",
    "Invalid verification link": "Lien de vérification invalide",
    "Verification link expired": "Lien de vérification expiré",
    "Email verified successfully": "Email vérifié avec succès",
    "User not found": "Utilisateur non trouvé",
    "Speciality": "Spécialité",
    "Logo": "Logo",
    "Upload Logo": "Télécharger Logo",
    "I agree to": "J'accepte la",
    "Terms & Conditions": "Termes & Conditions",
    "privacy policy & terms": "politique de confidentialité et conditions",
    "This field is required": "Ce champ est obligatoire",
    "Please enter a valid email address": "Veuillez saisir une adresse e-mail valide",
    "The field must contain at least one uppercase, one lowercase, one special character and one number with a minimum of 8 characters": "Le champ doit contenir au moins une majuscule, une minuscule, un caractère spécial et un chiffre avec un minimum de 8 caractères",
    "The password confirmation does not match": "La confirmation du mot de passe ne correspond pas",
    "Enter number between": "Saisir un numéro entre",
    "and": "et",
    "This field must be an integer": "This field must be an integer",
    "Operating Techniques": "Techniques Opératoires",
    "Select date": "Sélectionner une date",
    "Billed": "Facturée",
    "Create Follow-Up": "Créer une fiche médicale",
    "Edit Follow-Up": "Modifier une fiche médicale",
    "Back": "Retour",
    "Same Day": "Jour Même",
    "Dates": "Dates",
    "Files": "Fichiers",
    "Title is required": "Le titre est requis",
    "File is required": "Le fichier est requis",
    "Add Files from URL": "Ajouter des fichiers à partir d'une URL",
    "Drag and Drop Your Image Here": "Glissez-déposez votre image ici",
    "Browse Images": "Parcourir les images",
    "Remove File": "Supprimer le fichier",
    "or": "ou",
    "Add Anesthesiologist": "Ajouter un anesthésiste",
    "Document transferred successfully.": "Document transféré avec succès.",
    "An error has occured, try again later.": "Une erreur s'est produite, réessayez plus tard.",
    "No diagnostics recorded for this consultation.":"Aucun diagnostic n'a été enregistré pour cette consultation.",
    "No techniques recorded for this intervention.":"Aucune technique n'a été enregistrée pour cette intervention.",
    "No disease history recorded for this consultation.": "Aucune histoire de maladie n'a été enregistré pour cette consultation.",
    "No operative report recorded for this intervention.": "Aucun rapport d'opération n'a été enregistré pour cette intervention.",
    "No content recorded for this control.": "Aucun contenu enregistré pour ce contrôle.",
    "No media available": "Pas de média disponible",
    "There are no files to display for the selected criteria": "Il n'y a pas de fichiers à afficher pour les critères sélectionnés.",
    "Account Setup": "Configuration De Compte",
    "Account Configured Successfully": "Compte configuré avec succès",
    "Join": "Rejoindre",
    "minCharField": "Ce champ doit comporter au moins {length} caractères.",
    "You are not authorized": "Vous n'êtes pas autorisé",
    "You do not have permission to view this page using the credentials that you have provided while login.": "Vous n'avez pas l'autorisation de consulter cette page en utilisant les informations d'identification que vous avez fournies lors de votre connexion.",
    "If you believe this is a mistake, please Please contact your site administrator.": "Si vous pensez qu'il s'agit d'une erreur, veuillez contacter l'administrateur de votre site.",
    "Administratif Access": "Accès Administratif",
    "Terminal": "Terminal",
    "Appointment Management": "Gestion des rendez-vous",
    "Patient Management": "Gestion des patients",
    "Doctor Management": "Gestion des médecins",
    "Medication Management": "Gestion des médicaments",
    "Diagnostic Management": "Gestion des diagnostics",
    "Technique Management": "Gestion des techniques",
    "Consent Management": "Gestion des consentements",
    "Service Management": "Gestion des services",
    "Bill Management": "Gestion des notes d'honoraire",
    "Consultation Management": "Gestion des consultations",
    "Control Management": "Gestion des contrôle",
    "Calendar Management": "Gestion de l'agenda",
    "Add Role": "Ajouter Rôle",
    "Create Role": "Créer Rôle",
    "Edit Role": "Modifier Rôle",
    "Role List": "Liste des rôles",
    "Administrator Access": "Accès Administrateur",
    "Select All": "Sélectionner Tout",
    "Read": "Lire",
    "Write": "Ecrire",
    "Delete": "Supprimer",
    "Please select at least one permission before saving.": "Veuillez sélectionner au moins une autorisation avant de sauvegarder.",
    "Total Users": "Total des utilisateurs",
    "Medical Specialty": "Spécialité Médicale",
    "Status": "Statut",
    "Confirmed": "Confirmé",
    "Not Confirmed": "Pas Confirmé",
    "Services": "Services",
    "Bills": "Notes D'Honoraire",
    "Activity": "Activité",
    "No Activity Yet": "Pas encore d'activité",
    "There are no activities to display at the moment. New activities will appear here as they occur.": "Il n'y a pas d'activités à afficher pour le moment. De nouvelles activités apparaîtront ici au fur et à mesure.",
    "Webmail": "Messagerie",
    "Sidebar Configuration": "Configuration de la barre latérale",
    "Theme": "Thème",
    "Theme reinitialized successfully": "Thème réinitialisé avec succès",
    "Theme saved successfully": "Thème sauvegardé avec succès",
    "Theme Settings": "Paramètres du thème",
    "Primary Color": "Couleur primaire",
    "Skins": "Skins",
    "Default": "Défaut",
    "Bordered": "Bordé",
    "Semi Dark Menu": "Menu semi-foncé",
    "Layout": "Mise en page",
    "Vertical": "Vertical",
    "Collapsed": "Effondré",
    "Horizontal": "Horizontal",
    "Compact": "Compact",
    "Wide": "Large",
    "Direction": "Direction",
    "Left to right": "Gauche à droite",
    "Right to left": "Droite à gauche",
    "Account created successfully": "Compte créé avec succès",
    "Creating": "Création",
    "created successfully": "créé avec succès",
    "updated successfully": "mise à jour avec succès",
    "Updating": "Mise à jour",
    "An error has occured": "Une erreur s'est produite",
    "ofPatient": "du patient",
    "ofAppointment": "du rendez-vous",
    "ofConsent": "du consentement",
    "ofMedication": "du médicament",
    "ofDiagnostic": "du diagnostic",
    "ofTechnique": "de la technique",
    "ofDoctor": "du médecin",
    "ofUser": "de l'utilisateur",
    "ofRole": "du rôle",
    "ofService": "du service",
    "ofBill": "de la facture",
    "ofFollow Up": "du fiche médical",
    "ofConsultation": "du consultation",
    "ofIntervention": "d'intervention",
    "ofControl": "du contrôle",
    "Deletion Failed": "Échec de la suppression",
    "Operation Failed": "Échec de l'opération",
    "Storage limit exceeded. Consider upgrading your plan.": "Limite de stockage dépassée. Envisagez de mettre à niveau votre plan.",
    "Delete Confirmation": "Confirmation Suppression",
    "deletePatientMessage": "Êtes-vous sûr de vouloir supprimer le patient {name} ?",
    "deleteFollowUpMessage": "Êtes-vous sûr de vouloir supprimer la fiche médicale du {name} ?",
    "deleteAppointmentMessage": "Êtes-vous sûr de vouloir supprimer le rendez-vous {name} ?",
    "deleteBillMessage": "Êtes-vous sûr de vouloir supprimer la facture pour {name} ?",
    "deleteServiceMessage": "Êtes-vous sûr de vouloir supprimer le service {name} ?",
    "deleteConsentMessage": "Êtes-vous sûr de vouloir supprimer le formulaire de consentement {name} ?",
    "deleteConsultationMessage": "Êtes-vous sûr de vouloir supprimer la consultation {name} ?",
    "deleteControlMessage": "Êtes-vous sûr de vouloir supprimer le contrôle {name} ?",
    "deleteDiagnosticMessage": "Êtes-vous sûr de vouloir supprimer le diagnostic {name} ?",
    "deleteDoctorMessage": "Êtes-vous sûr de vouloir supprimer le médecin {name} ?",
    "deleteMedicationMessage": "Êtes-vous sûr de vouloir supprimer le médicament {name} ?",
    "deleteTechniqueMessage": "Êtes-vous sûr de vouloir supprimer la technique {name} ?",
    "deleteUserMessage": "Êtes-vous sûr de vouloir supprimer le compte utilisateur de {name} ?",
    "deleteRoleMessage": "Êtes-vous sûr de vouloir supprimer le rôle {name} ?",
    "deleteOwnerMessage": "Êtes-vous sûr de vouloir supprimer le propriétaire {name} ?",
    "deleteLicenseMessage": "Êtes-vous sûr de vouloir supprimer la licence {name} ?",
    "deleteVersionMessage": "Êtes-vous sûr de vouloir supprimer la version {name} ?",
    "deleteSpecialityMessage": "Êtes-vous sûr de vouloir supprimer la spécialité {name} ?",
    "deletePlanMessage": "Êtes-vous sûr de vouloir supprimer le plan {name} ?",
    "deleteTutorialMessage": "Êtes-vous sûr de vouloir supprimer le tutoriel {name} ?",
    "deleteNotificationMessage": "Êtes-vous sûr de vouloir supprimer la notification {name} ?",
    "deleteMultimediaMessage": "Êtes-vous sûr de vouloir supprimer l'élément multimédia {name}?",
    "deleteDatabaseMessage": "Êtes-vous sûr de vouloir supprimer la base de données {name}?",
    "deleteTableMessage": "Êtes-vous sûr de vouloir supprimer la table {name}?",
    "deleteDemoMessage": "Êtes-vous sûr de vouloir supprimer la demande de démo pour {name}?",
    "deleteInterventionMessage": "Êtes-vous sûr de vouloir supprimer l'intervention {name}?",
    "Restore Confirmation": "Confirmation Restauration",
    "restorePatientMessage": "Êtes-vous sûr de vouloir restaurer le patient {name} ?",
    "restoreFollowUpMessage": "Êtes-vous sûr de vouloir restaurer le suivi du patient {name} ?",
    "restoreAppointmentMessage": "Êtes-vous sûr de vouloir restaurer le rendez-vous pour {name} ?",
    "restoreBillMessage": "Êtes-vous sûr de vouloir restaurer la facture pour {name} ?",
    "restoreServiceMessage": "Êtes-vous sûr de vouloir restaurer le service {name} ?",
    "restoreConsentMessage": "Êtes-vous sûr de vouloir restaurer le formulaire de consentement pour {name} ?",
    "restoreConsultationMessage": "Êtes-vous sûr de vouloir restaurer la consultation {name} ?",
    "restoreControlMessage": "Êtes-vous sûr de vouloir restaurer le contrôle {name} ?",
    "restoreDiagnosticMessage": "Êtes-vous sûr de vouloir restaurer le diagnostic {name} ?",
    "restoreDoctorMessage": "Êtes-vous sûr de vouloir restaurer le médecin {name} ?",
    "restoreMedicationMessage": "Êtes-vous sûr de vouloir restaurer le médicament {name} ?",
    "restoreTechniqueMessage": "Êtes-vous sûr de vouloir restaurer la technique {name} ?",
    "restoreUserMessage": "Êtes-vous sûr de vouloir restaurer le compte utilisateur de {name} ?",
    "restoreRoleMessage": "Êtes-vous sûr de vouloir restaurer le rôle de {name} ?",
    "This action cannot be undone.": "Cette action est irréversible.",
    "docOverview": "Guide général de la documentation",
    "docMessage": "Parcourez nos tutoriels vidéo et nos guides écrits pour vous familiariser avec l'application et faciliter votre travail.",
    "All Tutorials": "Tous les tutoriels",
    "Watch": "Regarder",
    "created an appointment": "a créé un rendez-vous",
    "created a bill": "a créé une facture",
    "created a consent": "a créé un consentement",
    "created a diagnostic": "a créé un diagnostic",
    "created a doctor": "a créé un docteur",
    "created a follow up": "a créé un suivi",
    "created a medication": "a créé un médicament",
    "created a patient": "a créé un patient",
    "created a service": "a créé un service",
    "created a technique": "a créé une technique",
    "created a consultation": "a créé une consultation",
    "created an intervention": "a créé une intervention",
    "created a control": "a créé un control",
    "updated an appointment": "a mis à jour un rendez-vous",
    "updated a bill": "a mis à jour une facture",
    "updated a consent": "a mis à jour un consentement",
    "updated a diagnostic": "a mis à jour un diagnostic",
    "updated a doctor": "a mis à jour un docteur",
    "updated a follow up": "a mis à jour un suivi",
    "updated a medication": "a mis à jour un médicament",
    "updated a patient": "a mis à jour un patient",
    "updated a service": "a mis à jour un service",
    "updated a technique": "a mis à jour une technique",
    "updated a consultation": "a mis à jour une consultation",
    "updated an intervention": "a mis à jour une intervention",
    "updated a control": "a mis à jour un contrôle",
    "deleted an appointment": "a supprimé un rendez-vous",
    "deleted a bill": "a supprimé une facture",
    "deleted a consent": "a supprimé un consentement",
    "deleted a diagnostic": "a supprimé un diagnostic",
    "deleted a doctor": "a supprimé un docteur",
    "deleted a follow up": "a supprimé un suivi",
    "deleted a medication": "a supprimé un médicament",
    "deleted a patient": "a supprimé un patient",
    "deleted a service": "a supprimé un service",
    "deleted a technique": "a supprimé une technique",
    "deleted a consultation": "a supprimé une consultation",
    "deleted an intervention": "a supprimé une intervention",
    "deleted a control": "a supprimé un control",
    "Patient deleted successfully":"Patient supprimé avec succès",
    "Appointment deleted successfully": "Rendez-vous supprimé avec succès",
    "Consultation deleted successfully": "Consultation supprimée avec succès",
    "Intervention deleted successfully": "Intervention supprimée avec succès",
    "Control deleted successfully": "Contrôle supprimé avec succès",
    "Trash": "Corbeille",
    "System Administration": "Administration Système",
    "Owners": "Propriétaires",
    "Owner List": "Liste des propriétaires",
    "Edit Owner": "Modifier Propriétaire",
    "Licenses": "Licences",
    "License List": "Liste des licences",
    "Key": "Clé",
    "Activated On": "Activé Le",
    "Activated By": "Activé Par",
    "Expires On": "Expire Le",
    "Add License": "Ajouter Licence",
    "Create License": "Créer Licence",
    "Edit License": "Modifier Licence",
    "Sub Specialty": "Sous Spécialité",
    "Specialties": "Spécialités",
    "Speciality List": "Liste des spécialités",
    "Add Speciality": "Ajouter Spécialité",
    "Create Speciality": "Créer Spécialité",
    "Edit Speciality": "Modifier Spécialité",
    "Plans": "Plans",
    "Plan List": "Liste des Plans",
    "Add Plan": "Ajouter Plan",
    "Create Plan": "Créer Plan",
    "Edit Plan": "Modifier Plan",
    "Storage": "Stockage",
    "Max Patients": "Nombre maximal de patients",
    "Max Users": "Nombre maximal d'utilisateurs",
    "Access": "Accès",
    "Versions": "Versions",
    "Version": "Version",
    "Code": "Code",
    "Version List": "Liste des versions",
    "Variable": "Variable",
    "Add Version": "Ajouter Version",
    "Create Version": "Créer Version",
    "Edit Version": "Modifier Version",
    "Databases": "Bases de données",
    "Database List": "Liste des bases de données",
    "Add Database": "Ajouter Base de Données",
    "Create Database": "Créer Base de Données",
    "Edit Database": "Modifier Base de Données",
    "Demo Requests": "Demandes de démos",
    "Demo Request List": "Liste des demandes de démo",
    "Add Demo Request": "Ajouter Demande démo",
    "Create Demo Request": "Créer une demande de démonstration",
    "Link": "Lien",
    "Edit Demo Request": "Modifier une demande de démonstration",
    "Completed": "Complété",
    "Not Completed": "Non Complété",
    "Inquiries": "Enquêtes",
    "Inquiry List": "Liste des enquêtes",
    "Inquiry not found": "Enquête non trouvée",
    "Inquiry Details": "Détails de l'enquête",
    "Mark as Completed": "Mark as Completed",
    "Mark as Not Completed": "Mark as Not Completed",
    "Length": "Longueur",
    "Null": "Null",
    "Create Table":"Créer Table",
    "Edit Table": "Modifier Table",
    "Add Column": "Ajouter Colonne",
    "Table name is required": "Le nom de la table est obligatoire",
    "Save Changes": "Enregistrer",
    "Enter custom default": "Entrer la valeur par défaut",
    "None": "Aucun",
    "Attributes": "Attributs",
    "Constraints": "Contraintes",
    "Add Constraint": "Ajouter une contrainte",
    "Constraint Type": "Type de contrainte",
    "Column": "Colonne",
    "Reference Table": "Table de référence",
    "Manage Constraints": "Gérer les contraintes",
    "Primary Key on column": "Clé primaire sur la colonne",
    "Unique constraint on column": "Contrainte unique sur la colonne",
    "Foreign Key": "Clé Etrangère",
    "references": "références",
    "Auto Increment": "Incrémentation Automatique",
    "Primary Key": "Clé Primaire",
    "Tutorials": "Tutoriels",
    "Tutorial List": "Liste des tutoriels",
    "Add Tutorial": "Ajouter Tutoriel",
    "Create Tutorial": "Créer Tutoriel",
    "Edit Tutorial": "Modifier Tutoriel",
    "Thumbnail": "Vignette",
    "Video": "Vidéo",
    "Tag": "Étiquette",
    "Language": "Langue",
    "Notification List": "Liste des notifications",
    "Add Notification": "Ajouter Notification",
    "Create Notification": "Créer Notification",
    "Edit Notification": "Modifier Notification",
    "Personal": "Personnel",
    "Tenant": "Locataire",
    "Icon": "Icône",
    "Color": "Couleur",
    "Arabic Content": "Contenu en arabe",
    "French Content": "Contenu en français",
    "English Content": "Contenu en anglais",
    "Database": "Base de données",
    "UI Elements": "ÉLÉMENTS DE L'UI",
    "Forms & Tables": "Formulaires et tableaux",
    "Pages": "Des pages",
    "Charts & Maps": "Graphiques et cartes",
    "Others": "Autres",
    "Typography": "Typographie",
    "Cards": "Cartes",
    "Basic": "Basique",
    "Advance": "Avance",
    "Widgets": "Widget",
    "Card Action": "Action de la carte",
    "Components": "Composants",
    "Alert": "Alerte",
    "Close Alert": "Fermer l'alerte",
    "Avatar": "Avatar",
    "Badge": "Badge",
    "Button": "Bouton",
    "Calendar": "Agenda",
    "Image": "Image",
    "Pagination": "Pagination",
    "Progress Circular": "Progrès circulaire",
    "Progress Linear": "Progrès Linéaire",
    "Autocomplete": "Saisie automatique",
    "Tooltip": "Info-bulle",
    "Slider": "Glissière",
    "Date Time Picker": "Sélecteur de date et d'heure",
    "Select": "Sélectionner",
    "Switch": "Commutateur",
    "Checkbox": "Case à cocher",
    "Radio": "Radio",
    "Textarea": "Textarea",
    "Rating": "Évaluation",
    "File Input": "Entrée de fichier",
    "Otp Input": "Entrée Otp",
    "Form Layout": "Disposition du formulaire",
    "Form Validation": "Validation de formulaire",
    "Charts": "Graphiques",
    "Apex Chart": "Graphique Apex",
    "Chartjs": "Chartjs",
    "Account": "Compte",
    "Active": "Actif",
    "Suspended": "Suspendu",
    "Account Settings": "Paramètres du compte",
    "User Profile": "Profil de l'utilisateur",
    "FAQ": "FAQ",
    "Dialog Examples": "Exemples de dialogue",
    "Pricing": "Tarification",
    "List": "liste",
    "Edit": "Modifier",
    "Nav Levels": "Niveaux de navigation",
    "Level 2.1": "Niveau 2.1",
    "Level 2.2": "Niveau 2.2",
    "Level 3.1": "Niveau 3.1",
    "Level 3.2": "Niveau 3.2",
    "Raise Support": "Augmenter le soutien",
    "Documentation": "Documentation",
    "Dashboards": "Tableaux de bord",
    "Analytics": "Analytique",
    "Apps & Pages": "Applications et pages",
    "Email": "Email",
    "Chat": "Bavarder",
    "Invoice": "Facture d'achat",
    "Preview": "Aperçu",
    "Add": "Ajouter",
    "Update": "Modifier",
    "User": "Utilisateur",
    "View": "Vue",
    "Login v1": "Connexion v1",
    "Login v2": "Connexion v2",
    "Login": "Connexion",
    "Registration": "Inscription",
    "Register v1": "S'inscrire v1",
    "Register v2": "S'inscrire v2",
    "Register": "S'inscrire",
    "Forget Password v1": "Oubliez le mot de passe v1",
    "Forget Password v2": "Oubliez le mot de passe v2",
    "Forgot Password v1": "Oubliez le mot de passe v1",
    "Forgot Password v2": "Oubliez le mot de passe v2",
    "Forgot Password": "Mot de passe oublié",
    "Reset Password v1": "Réinitialiser le mot de passe v1",
    "Reset Password v2": "Réinitialiser le mot de passe v2",
    "Reset Password": "Réinitialisation du mot de passe",
    "Miscellaneous": "Divers",
    "Coming Soon": "Bientôt Disponible",
    "This feature is under development and will be available shortly": "Cette fonctionnalité est en cours de développement et sera disponible prochainement",
    "Thank you for your patience": "Merci de votre patience",
    "Not Authorized": "Pas autorisé",
    "Under Maintenance": "En maintenance",
    "Error": "Erreur",
    "Statistics": "Statistiques",
    "Blood Groups": "Groupes Sanguins",
    "Monthly Visits": "Visites Mensuelles",
    "Anesthesiologists": "Anesthésistes",
    "Year": "Année",
    "Revenu": "Revenu",
    "Monthly Consultations": "Consultations Mensuelles",
    "Genders": "Genres",
    "Card Actions": "Actions de la carte",
    "Actions": "Actions",
    "Access Control": "Contrôle d'accès",
    "User Interface": "Interface utilisateur",
    "CRM": "CRM",
    "eCommerce": "commerce électronique",
    "Icons": "Icône",
    "Chip": "Ébrécher",
    "Dialog": "Dialogue",
    "Expansion Panel": "Panneau d'extension",
    "Combobox": "Boîte combo",
    "Textfield": "Champ de texte",
    "Range Slider": "Curseur Gamme",
    "Menu": "Menu",
    "Utilities": "Utilitaires",
    "Healthcare Management": "Gestion des soins de santé",
    "Scheduling & Planning": "Planification & Programmation",
    "Medical Database": "Base de données médicale",
    "User Management": "Gestion des utilisateurs",
    "Financial Management": "Gestion financière",
    "Communication Tools": "Outils de communication",
    "Reporting Tools": "Outils de rapport",
    "Documentation & Resources": "Documentation & Ressources",
    "Snackbar": "Casse-croûte",
    "Tabs": "Onglets",
    "Home": "Accueil",
    "Access Denied": "Accès Refusé",
    "You do not have the necessary permissions to access this page": "Vous n’avez pas les permissions nécessaires pour accéder à cette page.",
    "Form Elements": "Éléments de formulaire",
    "Form Layouts": "Dispositions de formulaire",
    "Authentication": "Authentification",
    "Page Not Found - 404": "Page introuvable - 404",
    "Page Not Found": "Page Non Trouvée",
    "We couldn't find the page you are looking for": "La page recherchée n'a pas pu être trouvée",
    "500_ERROR_TITLE": "Erreur Interne du Serveur",
    "500_ERROR_MESSAGE": "Le serveur a rencontré une erreur interne ou une mauvaise configuration et n'a pas pu compléter votre demande.",
    "Not Authorized - 401": "Non autorisé - 401",
    "Server Error - 500": "Erreur de serveur - 500",
    "Forms": "Formes",
    "Timeline": "Chronologie",
    "Show More": "Afficher Plus",
    "Show Less": "Afficher Moins",
    "Disabled Menu": "Menu désactivé",
    "Help Center": "Centre d'aide",
    "Verify Email": "Vérifier les courriels",
    "Verify Email v1": "Vérifier l'e-mail v1",
    "Verify Email v2": "Vérifier l'e-mail v2",
    "Two Steps": "Deux étapes",
    "Two Steps v1": "Deux étapes v1",
    "Two Steps v2": "Deux étapes v2",
    "Custom Input": "Entrée personnalisée",
    "Extensions": "Rallonges",
    "Tour": "Tour",
    "Register Multi-Steps": "Enregistrer plusieurs étapes",
    "Wizard Examples": "Exemples de guide",
    "Checkout": "Check-out",
    "Create Deal": "Créer une offre",
    "Property Listing": "Liste des propriétés",
    "Roles & Permissions": "Rôles et autorisations",
    "Roles": "Rôles",
    "Role": "Rôle",
    "Permissions": "Autorisations",
    "Permission": "Permission",
    "Simple Table": "Table simple",
    "Tables": "Tables",
    "Data Table": "Table de données",
    "Apps": "Applications",
    "Misc": "Divers",
    "Wizard Pages": "Pages de l'assistant",
    "Form Wizard": "Assistant de formulaire",
    "Numbered": "Numéroté",
    "ecommerce": "commerce électronique",
    "Ecommerce": "Commerce électronique",
    "Product": "Produit",
    "Category": "Catégorie",
    "Order": "Ordre",
    "Details": "Détails",
    "Customer": "Client",
    "Manage Review": "Gérer la revue",
    "Referrals": "Références",
    "Settings": "Paramètres",
    "Course Details": "Détails du cours",
    "My Course": "Mon cours",
    "Overview": "Aperçu",
    "Academy": "Académie",
    "Logistics": "Logistique",
    "Dashboard": "Accueil",
    "Loading Events":"Chargement des événements",
    "Loading Appointments":"Chargement des rendez-vous",
    "Loading Patients":"Chargement des patients",
    "Fleet": "Flotte",
    "Editors": "Éditeurs",
    "Front Pages": "Pages frontales",
    "Landing": "d'atterrissage",
    "checkout": "Check-out",
    "Payment": "Paiement",
    "Swiper": "Swiper",
    "Total": "Total",
    "Week": "Semaine",
    "January": "Janvier",
    "February": "Février",
    "March": "Mars",
    "April": "Avril",
    "May": "Mai",
    "June": "Juin",
    "July": "Juillet",
    "August": "Août",
    "September": "Septembre",
    "October": "Octobre",
    "November": "Novembre",
    "December": "Décembre",
    "Jan": "Janv",
    "Feb": "Févr",
    "Mar": "Mars",
    "Apr": "Avr",
    "Jun": "Juin",
    "Jul": "Juil",
    "Aug": "Août",
    "Sep": "Sept",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Déc",
    "$vuetify": {
        "badge": "Badge",
        "noDataText": "Pas de données disponibles",
        "close": "Fermer",
        "open": "Ouvert",
        "carousel": {
            "ariaLabel": {
                "delimiter": "délimiteur"
            }
        },
        "dataFooter": {
            "itemsPerPageText": "Objets par page:",
            "itemsPerPageAll": "Tout",
            "pageText": "{0}-{1} of {2}",
            "firstPage": "Première page",
            "prevPage": "Page précédente",
            "nextPage": "Page suivante",
            "lastPage": "Dernière page"
        },
        "pagination": {
            "ariaLabel": {
                "root": "racine",
                "previous": "précédente",
                "next": "suivante",
                "currentPage": "page actuelle",
                "page": "page"
            }
        },
        "input": {
            "clear": "dégager",
            "appendAction": "ajouter une action",
            "prependAction": "préfixer l'action",
            "otp": "otp"
        },
        "fileInput": {
            "counterSize": "Taille du compteur"
        },
        "rating": {
            "ariaLabel": {
                "item": "Objet"
            }
        },
        "datePicker": {
            "title": "Title",
            "header": "Header"
        }
    }
}