{
    "seo": {
        "title": "MedSyn Solutions - Intelligent Medical Software",
        "description": "MedSyn offers a comprehensive AI-powered software for efficient patient management and improved healthcare."
    },
    "nav": {
        "home": "Home",
        "specialties": "Specialties",
        "features": "Features",
        "technology": "Technology",
        "pricing": "Pricing",
        "faq": "FAQ",
        "contact": "Contact"
    },
    "2": "2",
    "3": "3",
    "5": "5",
    "10": "10",
    "20": "20",
    "25": "25",
    "50": "50",
    "100": "100",
    "Features":"Features",
    "Revolutionize Your Medical Practice with MedSyn":"Revolutionize Your Medical Practice with MedSyn",
    "heroText": "Comprehensive, AI-powered software for efficient patient management and improved healthcare delivery",
    "Demo Request": "Demo Request",
    "Fill out the form below to request a demo of MedSyn.": "Fill out the form below to request a demo of MedSyn.",
    "Submit Request": "Submit Request",
    "Tailored for Your Expertise": "Tailored for Your Expertise",
    "Specialized solutions for diverse medical fields": "Specialized solutions for diverse medical fields",
    "Pediatric Surgery": "Pediatric Surgery",
    "Providing specialized care for infants, children, and adolescents with a range of surgical needs.": "Providing specialized care for infants, children, and adolescents with a range of surgical needs.",
    "General Surgery": "General Surgery",
    "Comprehensive surgical solutions addressing a broad spectrum of conditions.": "Comprehensive surgical solutions addressing a broad spectrum of conditions.",
    "Cosmetic Surgery": "Cosmetic Surgery",
    "Advanced cosmetic procedures to enhance and refine your natural beauty.": "Advanced cosmetic procedures to enhance and refine your natural beauty.",
    "Core Features": "Core Features",
    "Empower Your Practice": "Empower Your Practice",
    "Comprehensive tools to streamline your workflow": "Comprehensive tools to streamline your workflow",
    "Manage patient records, consultations, interventions, controls, and medical files.": "Manage patient records, consultations, interventions, controls, and medical files.",
    "Efficiently schedule and manage patient appointments with an intuitive interface.": "Efficiently schedule and manage patient appointments with an intuitive interface.",
    "Document Printing": "Document Printing",
    "Easily create and print all sort of medical documents prefilled with your patient's data.": "Easily create and print all sort of medical documents prefilled with your patient's data.",
    "Extensive Medical Database": "Extensive Medical Database",
    "Access and manage an extensive medical database tailored to your specialty.": "Access and manage an extensive medical database tailored to your specialty.",
    "Advanced Statistics": "Advanced Statistics",
    "Gain valuable insights into your practice with detailed analytics and reporting tools.":"Gain valuable insights into your practice with detailed analytics and reporting tools.",    
    "Integrated Calendar": "Integrated Calendar",
    "Streamline your schedule with a comprehensive calendar system for interventions, consultations, appointments and other tasks.": "Streamline your schedule with a comprehensive calendar system for interventions, consultations, appointments and other tasks.",
    "Efficiently manage multiple users with customizable roles and permissions.": "Efficiently manage multiple users with customizable roles and permissions.",
    "Billing Management": "Billing Management",
    "Simplify your financial operations with integrated billing and invoicing features.": "Simplify your financial operations with integrated billing and invoicing features.",
    "Intelligent Chatbot":"Intelligent Chatbot",
    "Get instant assistance and answers to your queries with our built-in chatbot.":"Get instant assistance and answers to your queries with our built-in chatbot.",
    "Technology":"Technology",
    "Advancing Medical Practice":"Advancing Medical Practice",
    "AI-driven tools, robust security, and seamless multilingual support":"AI-driven tools, robust security, and seamless multilingual support",
    "AI-Powered":"AI-Powered",
    "Leverage cutting-edge AI for automatic report generation, speech-to-EHR functionality, and intelligent diagnostic suggestions.":"Leverage cutting-edge AI for automatic report generation, speech-to-EHR functionality, and intelligent diagnostic suggestions.",
    "Secure & Compliant":"Secure & Compliant",
    "HIPAA-compliant multi-tenant architecture ensures the highest level of data security and privacy for your practice and patients.":"HIPAA-compliant multi-tenant architecture ensures the highest level of data security and privacy for your practice and patients.",
    "Multilingual Support":"Multilingual Support",
    "Seamlessly switch between Arabic, French, and English to serve diverse patient populations and expand your practice reach.":"Seamlessly switch between Arabic, French, and English to serve diverse patient populations and expand your practice reach.",
    "Reviews":"Reviews",
    "Trusted by Healthcare Professionals":"Trusted by Healthcare Professionals",
    "What our users say about MedSyn Solutions":"What our users say about MedSyn Solutions",
    "Choose Your Plan":"Choose Your Plan",
    "Flexible options to suit your practice needs":"Flexible options to suit your practice needs",
    "Pay Monthly":"Pay Monthly",
    "Pay Annual":"Pay Annual",
    "Advanced":"Advanced",
    "Premium":"Premium",
    "Email Address":"Email Address",
    "Up to 500 patients annually":"Up to 500 patients annually",
    "Up to 5GB Storage":"Up to 5GB Storage",
    "Extra Users": "Extra Users",
    "AI tools access": "AI tools access",
    "Up to 1000 patients annually": "Up to 1000 patients annually",
    "Up to 20GB Storage": "Up to 20GB Storage",
    "2 Extra Users": "2 Extra Users",
    "Limited AI tools access": "Limited AI tools access",
    "Up to 2000 patients annually": "Up to 2000 patients annually",
    "Up to 50GB Storage": "Up to 50GB Storage",
    "5 Extra Users": "5 Extra Users",
    "Full AI tools access": "Full AI tools access",
    "Frequently Asked Questions":"Frequently Asked Questions",
    "Get answers to common queries about MedSyn Solutions":"Get answers to common queries about MedSyn Solutions",
    "Q1":"How does MedSyn ensure patient data security?",
    "A1": "MedSyn employs state-of-the-art encryption and follows HIPAA guidelines to ensure the utmost security and privacy of patient data. We use secure cloud storage and provide role-based access control to limit data exposure.",
    "Q2": "What types of medical specialties does MedSyn Solutions support?",
    "A2": "MedSyn Solutions offers specialized interfaces for various medical fields, including pediatric surgery, general surgery, and aesthetic surgery. Our platform is designed to be adaptable to different specialties, with customizable features to meet the unique needs of each medical practice.",
    "Q3": "What kind of support does MedSyn offer?",
    "A3": "MedSyn provides 24/7 technical support via phone, email, and live chat. We also offer personalized onboarding, regular training sessions, and a comprehensive knowledge base to ensure you get the most out of our platform.",
    "Q4": "Is it possible to access MedSyn from multiple devices or locations?",
    "A4": "Yes, MedSyn is a cloud-based solution that can be accessed securely from any device with an internet connection. This allows healthcare professionals to access patient information and manage their practice from multiple locations, enhancing flexibility and efficiency.",
    "Q5": "Is there a mobile app available for MedSyn?",
    "A5": "Currently, MedSyn is available as a web application accessible from any device with a modern web browser. We are actively developing a mobile app to provide even more convenience and flexibility for our users. Stay tuned for updates on its release.",
    "Contact": "Contact",
    "Get in Touch": "Get in Touch",
    "We're here to support your practice": "We're here to support your practice",
    "Send us a message": "Send us a message",
    "ContactSubMsg": "Whether you have questions about features, trials, pricing, or anything else, our team is ready to answer all your questions",
    "Request a Demo": "Request a Demo",
    "Sent":"Sent",
    "Your request has been successfully submitted.":"Your request has been successfully submitted.",
    "Our team will contact you shortly to schedule your demo.":"Our team will contact you shortly to schedule your demo.",
    "Tools": "Tools",
    "Chatbot": "Chatbot",
    "Start":"Start",
    "Chat not activated":"Chat not activated",
    "Use the menu to start a chat":"Use the menu to start a chat",
    "No messages yet":"No messages yet",
    "Start typing to begin the conversation":"Start typing to begin the conversation",
    "Type your message...": "Type your message...",
    "Pediatric Surgeon": "Pediatric Surgeon",
    "General Surgeon": "General Surgeon",
    "Cosmetic Surgeon": "Cosmetic Surgeon",
    "MedSyn Access": "MedSyn Access",
    "Step": "Step",
    "Administrator": "Administrator",
    "MedSyn ID": "ID MedSyn",
    "Community & Networking": "Community & Networking",
    "Platform": "Platform",
    "Feed": "Feed",
    "Welcome": "Welcome",
    "Dismiss": "Dismiss",
    "Request timed out": "Request timed out",
    "Folder": "Folder",
    "Patients": "Patients",
    "Doctors": "Doctors",
    "Medications": "Medications",
    "Diagnostics": "Diagnostics",
    "Techniques": "Techniques",
    "Consents": "Consents",
    "Controls": "Controls",
    "Background": "Background",
    "Medical Background": "Medical Background",
    "Family Background": "Family Background",
    "Surgical Background":"Surgical Background",
    "Allergic Background":"Allergic Background",
    "MED_BG": "Medical",
    "FAM_BG": "Family",
    "SUR_BG": "Surgical",
    "ALE_BG": "Allergic",
    "Control": "Control",
    "Control List": "Control List",
    "Create Control": "Create Control",
    "Edit Control":"Edit Control",
    "Control Date":"Control Date",
    "Add Control": "Add Control",
    "Appointments": "Appointments",
    "Users": "Users",
    "Visits": "Visits",
    "Operations": "Operations",
    "Upcoming Events": "Upcoming Events",
    "Upcoming Appointments": "Upcoming Appointments",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Age": "Age",
    "Gender": "Gender",
    "Years": "Years",
    "Months": "Months",
    "Date": "Date",
    "Select Start Time":"Select Start Time",
    "Start Time": "Start Time",
    "End Time": "End Time",
    "At": "At",
    "View All": "View All",
    "Recent Patients": "Recent Patients",
    "Total Patients": "Total Patients",
    "Diagnosed Patients": "Diagnosed Patients",
    "Operated Patients": "Operated Patients",
    "Pending Patients": "Pending Patients",
    "Controlled Patients":"Controlled Patients",
    "Last week analytics": "Last week analytics",
    "Filters":"Filters",
    "Filter":"Filter",
    "Patient List": "Patient List",
    "Deleted Patient List": "Deleted Patient List",
    "Last Diagnostics": "Last Diagnostics",
    "Last Techniques": "Last Techniques",
    "Add Patient": "Add Patient",
    "SPEECH_EHR_INFO": "This tool allows you to record patient information verbally. Speak clearly about the patient's details such as name, date of birth, blood group, gender, and any medical or family background.",
    "Start Recording":"Start Recording",
    "Stop Recording":"Stop Recording",
    "Ready to record. Click 'Start Recording' when you're ready to speak.":"Ready to record. Click 'Start Recording' when you're ready to speak.",
    "Recording... Speak clearly about the patient's information.":"Recording... Speak clearly about the patient's information.",
    "Error accessing microphone. Please ensure you've granted microphone permissions.":"Error accessing microphone. Please ensure you've granted microphone permissions.",
    "Recording stopped. Processing...":"Recording stopped. Processing...",
    "Couldn't extract any information. Please try again.":"Couldn't extract any information. Please try again.",
    "Transcription successful.":"Transcription successful.",
    "Extracted Data":"Extracted Data",
    "Generate Report":"Generate Report",
    "Download PDF Report":"Download PDF Report",
    "Export": "Export",
    "Refresh": "Refresh",
    "Search": "Search",
    "Parent": "Parent",
    "Upload Photo": "Upload Photo",
    "Reset": "Reset",
    "Allowed JPG, GIF or PNG. Max size of 800K": "Allowed JPG, GIF or PNG. Max size of 800K",
    "Male": "Male",
    "Female": "Female",
    "Blood Group": "Blood Group",
    "Prescriber": "Prescriber",
    "Prescribers": "Prescribers",
    "Birth Date": "Birth Date",
    "Phone Number": "Phone Number",
    "Address": "Address",
    "Civil Status": "Civil Status",
    "Single": "Single",
    "Married": "Married",
    "Divorced": "Divorced",
    "Widowed": "Widowed",
    "Separated": "Separated",
    "Profession": "Profession",
    "Save": "Save",
    "Next": "Next",
    "Finish": "Finish",
    "Verify": "Verify",
    "Medical Folder": "Medical Folder",
    "Interventions": "Interventions",
    "Consultations": "Consultations",
    "Add Consultation": "Ajouter Consultation",
    "Consultation List":"Consultation List",
    "Create Consultation": "Create Consultation",
    "Edit Consultation": "Edit Consultation",
    "Create Intervention":"Create Intervention",
    "Edit Intervention":"Edit Intervention",
    "Follow Ups": "Follow Ups",
    "Media": "Media",
    "Documents": "Documents",
    "PATIENT": "PATIENT",
    "PARENT": "PARENT",
    "Not Defined": "Not Defined",
    "Create Patient": "Create Patient",
    "Edit Patient": "Edit Patient",
    "Previous": "Previous",
    "Disease": "Disease",
    "Interventation": "Interventation",
    "Multimedia":"Multimedia",
    "Add Media" :"Add Media",
    "Item":"Item",
    "Untitled":"Untitled",
    "File":"File",
    "Billing": "Billing",
    "Follow Up": "Follow Up",
    "Time": "Time",
    "Full Name": "Full Name",
    "Operator":"Operator",
    "Billed Person": "Billed Person",
    "Total Amount": "Total Amount",
    "Consultation Date": "Consultation Date",
    "Family History": "Family History",
    "Medical History": "Medical History",
    "Disease History": "Disease History",
    "Intervention Date": "Intervention Date",
    "Operative Techniques": "Operative Techniques",
    "Anesthetist": "Anesthetist",
    "Operative Report": "Operative Report",
    "Printing": "Printing",
    "Header": "Header",
    "Normal Text":"Normal Text",
    "Heading 1": "Heading 1",
    "Heading 2": "Heading 1",
    "Heading 3": "Heading 1",
    "Heading 4": "Heading 1",
    "Heading 5": "Heading 1",
    "Heading 6": "Heading 1",
    "Left":"Left",
    "Center": "Center",
    "Right": "Right",
    "Justify": "Justify",
    "Black":"Black",
    "Red": "Red",
    "Green": "Green",
    "Blue": "Blue",
    "Yellow": "Yellow",
    "Purple": "Purple",
    "Orange": "Orange",
    "Gray": "Gray",
    "Signature": "Signature",
    "Stamp": "Stamp",
    "Choose an Image (PNG, JPG, JPEG)": "Choose an Image (PNG, JPG, JPEG)",
    "Print": "Print",
    "Generate": "Generate",
    "Preoperative Assessment": "Preoperative Assessment",
    "Admission Letter": "Admission Letter",
    "Date & Time": "Date & Time",
    "Confidential Letter": "Confidential Letter",
    "Consent Form": "Consent Form",
    "Father": "Father",
    "Mother": "Mother",
    "Father Name": "Father Name",
    "Mother Name": "Mother Name",
    "Consent": "Consent",
    "Consultation":"Consultation",
    "Preoperative Consultation":"Preoperative Consultation",
    "PreoperativeConsultation": "PreoperativeConsultation",
    "Doctor Letter": "Doctor Letter",
    "Hospital Report": "Hospital Report",
    "Follow": "Follow",
    "Medical Certificate": "Medical Certificate",
    "Number of days": "Number of days",
    "Days": "Days",
    "Select Media": "Select Media",
    "Select follow up": "Select follow up",
    "Select Parent": "Select Parent",
    "Medical Prescription": "Medical Prescription",
    "Medication": "Medication",
    "Select Medication": "Select Medication",
    "Select date and time": "Select date and time",
    "Select Year": "Select Year",
    "Select consent": "Select consent",
    "Close": "Close",
    "Transfer": "Transfer",
    "Download": "Download",
    "All": "All",
    "Designation": "Designation",
    "Department": "Department",
    "Add Doctor": "Add Doctor",
    "Doctor List": "Doctor List",
    "Deleted Doctor List": "Deleted Doctor List",
    "Create Doctor": "Create Doctor",
    "Edit Doctor": "Edit Doctor",
    "Type": "Type",
    "Anesthesiologist": "Anesthesiologist",
    "Other": "Other",
    "Name": "Name",
    "Dosage": "Dosage",
    "Frequency": "Frequency",
    "Medication List": "Medication List",
    "Deleted Medication List": "Deleted Medication List",
    "Add Medication": "Add Medication",
    "Create Medication": "Create Medication",
    "Edit Medication": "Edit Medication",
    "Add Diagnostic": "Add Diagnostic",
    "History": "History",
    "Diagnostic List": "Diagnostic List",
    "Deleted Diagnostic List": "Deleted Diagnostic List",
    "Edit Diagnostic": "Edit Diagnostic",
    "Create Diagnostic": "Create Diagnostic",
    "Technique List": "Technique List",
    "Deleted Technique List": "Deleted Technique List",
    "Add Technique": "Add Technique",
    "Create Technique": "Create Technique",
    "Edit Technique": "Edit Technique",
    "Content": "Content",
    "Configuration": "Configuration",
    "Add Consent": "Add Consent",
    "Consent List": "Consent List",
    "Deleted Consent List": "Deleted Consent List",
    "Create Consent": "Create Consent",
    "Edit Consent": "Edit Consent",
    "Create Patient Consultation":"Create Patient Consultation",
    "Edit Patient Consultation": "Edit Patient Consultation",
    "Appointment List": "Appointment List",
    "Deleted Appointment List": "Deleted Appointment List",
    "Add Appointment": "Add Appointment",
    "From": "From",
    "To": "To",
    "Notes": "Notes",
    "Create Appointment": "Create Appointment",
    "Edit Appointment": "Edit Appointment",
    "Service": "Service",
    "Service List": "Service List",
    "Deleted Service List": "Deleted Service List",
    "Add Service": "Add Service",
    "Create Service": "Create Service",
    "Edit Service": "Edit Service",
    "Base Price": "Base Price",
    "Description": "Description",
    "Select Patient": "Select Patient",
    "Zip Code": "Zip Code",
    "Governorate": "Governorate",
    "Select Governorate": "Select Governorate",
    "Quantity is required": "Quantity is required",
    "Service is required for additional services": "Service is required for additional services",
    "Service is required": "Service is required",
    "Bill List": "Bill List",
    "Deleted Bill List": "Deleted Bill List",
    "Bill": "Bill",
    "Add Bill": "Add Bill",
    "Create Bill": "Create Bill",
    "Edit Bill": "Edit Bill",
    "Bill Service": "Bill Service",
    "Select Service": "Select Service",
    "Quantity": "Quantity",
    "Additional Amount": "Additional Amount",
    "User List": "User List",
    "Add User": "Add User",
    "Create User": "Create User",
    "Edit User": "Edit User",
    "Password Required": "Password Required",
    "Password": "Password",
    "Add Event": "Add Event",
    "Update Event": "Update Event",
    "Title": "Title",
    "Label": "Label",
    "Select Date": "Select Date",
    "Start Date": "Start Date",
    "Select Start Date": "Select Start Date",
    "End Date": "End Date",
    "Select End Date": "Select End Date",
    "All Day": "All Day",
    "Cancel": "Cancel",
    "Visit": "Visit",
    "Intervention": "Intervention",
    "Appointment": "Appointment",
    "Event": "Event",
    "FILTER": "FILTER",
    "View all": "View all",
    "General": "General",
    "Security": "Security",
    "Mailing": "Mailing",
    "Shortcuts": "Shortcuts",
    "Details Updated Successfully": "Details Updated Successfully",
    "Upload a new logo": "Upload a new logo",
    "General Details": "General Details",
    "Profile Picture": "Profile Picture",
    "Doctor Details": "Doctor Details",
    "Doctor Details Updated Successfully": "Doctor Details Updated Successfully",
    "Minimum 8 characters long": "Minimum 8 characters long",
    "At least one lowercase character": "At least one lowercase character",
    "At least one number, symbol, or whitespace character": "At least one number, symbol, or whitespace character",
    "Password Updated Successfully": "Password Updated Successfully",
    "Current Password": "Current Password",
    "New Password": "New Password",
    "Confirm New Password": "Confirm New Password",
    "Password Requirements": "Password Requirements",
    "Change Password": "Change Password",
    "Mailing Updated Successfully": "Mailing Updated Successfully",
    "Host": "Host",
    "Port": "Port",
    "Printing Updated Successfully": "Printing Updated Successfully",
    "Doctor": "Doctor",
    "Client": "Client",
    "Diagnostic": "Diagnostic",
    "Technique": "Technique",
    "View your patient list": "View your patient list",
    "View your doctor list": "View your doctor list",
    "View your medication list": "View your medication list",
    "View your diagnostic list": "View your diagnostic list",
    "View your technique list": "View your technique list",
    "View your consent list": "View your consent list",
    "View your appointment list": "View your appointment list",
    "View your user list": "View your user list",
    "View your service list": "View your service list",
    "View your bill list": "View your bill list",
    "Create a new patient record": "Create a new patient",
    "Create a new doctor record": "Create a new doctor",
    "Create a new medication record": "Create a new medication",
    "Create a new diagnostic record": "Create a new diagnostic",
    "Create a new technique record": "Create a new technique",
    "Create a new consent record": "Create a new consent",
    "Create a new appointment record": "Create a new appointment",
    "Create a new user record": "Create a new user",
    "Create a new service record": "Create a new service",
    "Create a new bill record": "Create a new bill",
    "List Patient": "Patient List",
    "List Doctor": "Doctor List",
    "List Medication": "Medication List",
    "List Diagnostic": "Diagnostic List",
    "List Technique": "Technique List",
    "List Consent": "Consent List",
    "List Appointment": "Appointment List",
    "List User": "User List",
    "List Service": "Service List",
    "List Bill": "Bill List",
    "Try searching for": "Try searching for",
    "No Result For": "No Result For",
    "system": "system",
    "light": "light",
    "dark": "dark",
    "English": "English",
    "French": "French",
    "Arabic": "Arabic",
    "Notifications": "Notifications",
    "Mark all as unread": "Mark all as unread",
    "Mark all as read": "Mark all as read",
    "No Notification Found": "No Notification Found",
    "View All Notifications": "View All Notifications",
    "Support": "Support",
    "Logout": "Logout",
    "Your journey to surgical innovation starts now.": "Your journey to surgical innovation starts now.",
    "Current Plan": "Current Plan",
    "Your Current Plan:": "Your Current Plan:",
    "Plan": "Plan",
    "Essential": "Essential",
    "Professional": "Professional",
    "Ultimate": "Ultimate",
    "Foundational access for healthcare professionals": "Foundational access for healthcare professionals",
    "Active until": "Active until",
    "You'll receive an alert before your plan concludes to ensure uninterrupted access.": "You'll receive an alert before your plan concludes to ensure uninterrupted access.",
    "100 TND Per Month": "100 TND Per Month",
    "ofDays": "{day} of {days} Days",
    "remainingDays": "{days} days remaining until your plan requires update",
    "Popular": "Popular",
    "Professional plan for individual practitioners and clinics": "Professional plan for individual practitioners and clinics",
    "Shortcuts Updated Successfully": "Shortcuts Updated Successfully",
    "user": "user",
    "Remember me": "Remember me",
    "Log In": "Log In",
    "Continue": "Continue",
    "Back to login": "Back to login",
    "Select doctor": "Select doctor",
    "Subject": "Subject",
    "Message": "Message",
    "Document Attached": "Document Attached",
    "Send": "Send",
    "A reset link has been sent": "A reset link has been sent",
    "Password has been successfully reset": "Password has been successfully reset",
    "Password Confirmation": "Password Confirmation",
    "Confirm": "Confirm",
    "Confirmation": "Confirmation",
    "Loading": "Loading",
    "Password Updated": "Password Updated",
    "admin": "admin",
    "NoData": "No data available",
    "Choose Your Role": "Choose Your Role",
    "Sign up as a medical professional": "Sign up as a medical professional",
    "Sign up as a patient": "Sign up as a patient",
    "Activation": "Activation",
    "Profile": "Profile",
    "License": "License",
    "License Key": "Licence Key",
    "Access Code": "Access Code",
    "Verification": "Verification",
    "Email Verification": "Email Verification",
    "Account activation link sent to your email address": "Account activation link sent to your email address",
    "Verification link has been sent successfully": "Verification link has been sent successfully",
    "Skip for now": "Skip for now",
    "Didn't get the mail": "Didn't get the mail",
    "Resend": "Resend",
    "Invalid verification link": "Invalid verification link",
    "Verification link expired": "Verification link expired",
    "Email verified successfully": "Email verified successfully",
    "User not found": "User not found",
    "Speciality": "Speciality",
    "Logo": "Logo",
    "Upload Logo": "Upload Logo",
    "I agree to": "I agree to",
    "Terms & Conditions": "Termes & Conditions",
    "privacy policy & terms": "privacy policy & terms",
    "This field is required": "This field is required",
    "Please enter a valid email address": "Please enter a valid email address",
    "The field must contain at least one uppercase, one lowercase, one special character and one number with a minimum of 8 characters": "The field must contain at least one uppercase, one lowercase, one special character and one number with a minimum of 8 characters",
    "The password confirmation does not match": "The password confirmation does not match",
    "Enter number between": "Enter number between",
    "and": "and",
    "This field must be an integer": "Ce champ doit être un nombre entier",
    "Operating Techniques": "Operating Techniques",
    "Select date": "Select date",
    "Billed": "Billed",
    "Create Follow-Up": "Create Follow-Up",
    "Edit Follow-Up": "Edit Follow-Up",
    "Back": "Back",
    "Same Day": "Same Day",
    "Dates": "Dates",
    "Files": "Files",
    "Title is required": "Title is required",
    "File is required": "File is required",
    "Add Files from URL": "Add Files from URL",
    "Drag and Drop Your Image Here": "Drag and Drop Your Image Here",
    "Browse Images": "Browse Images",
    "Remove File": "Remove File",
    "or": "or",
    "Add Anesthesiologist": "Add Anesthesiologist",
    "Document transferred successfully.": "Document transferred successfully.",
    "An error has occured, try again later.": "An error has occured, try again later.",
    "No diagnostics recorded for this consultation.": "No diagnostics recorded for this consultation.",
    "No techniques recorded for this intervention.": "No techniques recorded for this intervention.",
    "No disease history recorded for this consultation.":"No disease history recorded for this consultation.",
    "No operative report recorded for this intervention.": "No operative report recorded for this intervention.",
    "No content recorded for this control.":"No content recorded for this control.",
    "No media available":"No media available",
    "There are no files to display for the selected criteria":"There are no files to display for the selected criteria",
    "Account Setup": "Account Setup",
    "Account Configured Successfully": "Account Configured Successfully",
    "Join": "Join",
    "minCharField": "This field must be at least {length} characters.",
    "You are not authorized": "You are not authorized",
    "You do not have permission to view this page using the credentials that you have provided while login.": "You do not have permission to view this page using the credentials that you have provided while login.",
    "If you believe this is a mistake, please Please contact your site administrator.": "If you believe this is a mistake, please Please contact your site administrator.",
    "Appointment Management": "Appointment Management",
    "Patient Management": "Patient Management",
    "Doctor Management": "Doctor Management",
    "Medication Management": "Medication Management",
    "Diagnostic Management": "Diagnostic Management",
    "Technique Management": "Technique Management",
    "Consent Management": "Consent Management",
    "Service Management": "Service Management",
    "Bill Management": "Bill Management",
    "Consultation Management": "Consultation Management",
    "Control Management": "Control Management",
    "Calendar Management": "Calendar Management",
    "Add Role": "Add Role",
    "Create Role": "Create Role",
    "Edit Role": "Edit Role",
    "Role List": "Role List",
    "Administrator Access": "Administrator Access",
    "Terminal": "Terminal",
    "Select All": "Select All",
    "Read": "Read",
    "Write": "Write",
    "Delete": "Delete",
    "Please select at least one permission before saving.": "Please select at least one permission before saving.",
    "Total Users": "Total Users",
    "Medical Specialty": "Medical Specialty",
    "Status": "Status",
    "Confirmed": "Confirmed",
    "Not Confirmed": "Not Confirmed",
    "Services": "Services",
    "Bills": "Bills",
    "Activity": "Activity",
    "No Activity Yet":"No Activity Yet",
    "There are no activities to display at the moment. New activities will appear here as they occur.":"There are no activities to display at the moment. New activities will appear here as they occur.",
    "Webmail": "Webmail",
    "Sidebar Configuration": "Configuration de la barre latérale",
    "Theme": "Theme",
    "Theme reinitialized successfully": "Theme reinitialized successfully",
    "Theme saved successfully": "Theme saved successfully",
    "Theme Settings": "Theme Settings",
    "Primary Color": "Primary Color",
    "Skins": "Skins",
    "Default": "Default",
    "Bordered": "Bordered",
    "Semi Dark Menu": "Semi Dark Menu",
    "Layout": "Layout",
    "Vertical": "Vertical",
    "Collapsed": "Collapsed",
    "Horizontal": "Horizontal",
    "Compact": "Compact",
    "Wide": "Wide",
    "Direction": "Direction",
    "Left to right": "Left to right",
    "Right to left": "Right to left",
    "Account created successfully": "Account created successfully",
    "Creating": "Creating",
    "created successfully": "created successfully",
    "Updating": "Updating",
    "updated successfully": "updated successfully",
    "An error has occured": "An error has occured",
    "ofPatient": "Patient",
    "ofAppointment": "Appointment",
    "ofConsent": "Consent",
    "ofMedication": "Medication",
    "ofDiagnostic": "Diagnostic",
    "ofTechnique": "Technique",
    "ofDoctor": "Doctor",
    "ofUser": "User",
    "ofRole": "Role",
    "ofService": "Service",
    "ofBill": "Bill",
    "ofFollow Up": "Medical File",
    "ofConsultation": "Consultation",
    "ofControl": "Control",
    "ofIntervention": "Intervention",
    "Deletion Failed": "Deletion Failed",
    "Operation Failed": "Operation Failed",
    "Storage limit exceeded. Consider upgrading your plan.":"Storage limit would be exceeded. Unable to complete the operation.",
    "Delete Confirmation": "Delete Confirmation",
    "deletePatientMessage": "Are you sure you want to delete the patient {name}?",
    "deleteFollowUpMessage": "Are you sure you want to delete the follow up of {name}?",
    "deleteAppointmentMessage": "Are you sure you want to delete the appointment {name}?",
    "deleteBillMessage": "Are you sure you want to delete the bill for {name}?",
    "deleteServiceMessage": "Are you sure you want to delete the service {name}?",
    "deleteConsentMessage": "Are you sure you want to delete the consent {name}?",
    "deleteConsultationMessage": "Are you sure you want to delete the consultation {name}?",
    "deleteControlMessage": "Are you sure you want to delete the control {name}?",
    "deleteDiagnosticMessage": "Are you sure you want to delete the diagnostic {name}?",
    "deleteDoctorMessage": "Are you sure you want to delete the doctor {name}?",
    "deleteMedicationMessage": "Are you sure you want to delete the medication {name}?",
    "deleteTechniqueMessage": "Are you sure you want to delete the technique {name}?",
    "deleteUserMessage": "Are you sure you want to delete the user {name}?",
    "deleteRoleMessage": "Are you sure you want to delete the role {name}?",
    "deleteOwnerMessage": "Are you sure you want to delete the owner {name}?",
    "deleteLicenseMessage": "Are you sure you want to delete the license {name}?",
    "deleteVersionMessage": "Are you sure you want to delete the version {name}?",
    "deleteSpecialityMessage": "Are you sure you want to delete the speciality {name}?",
    "deletePlanMessage": "Are you sure you want to delete the plan {name}?",
    "deleteTutorialMessage": "Are you sure you want to delete the tutorial {name}?",
    "deleteNotificationMessage": "Are you sure you want to delete the notification {name}?",
    "deleteMultimediaMessage": "Are you sure you want to delete the multimedia item {name}?",
    "deleteDatabaseMessage": "Are you sure you want to delete the database {name}?",
    "deleteTableMessage": "Are you sure you want to delete the table {name}?",
    "deleteDemoMessage": "Are you sure you want to delete the demo request for {name}?",
    "deleteInterventionMessage": "Are you sure you want to delete the intervention {name}?",
    "Restore Confirmation": "Restore Confirmation",
    "restorePatientMessage": "Are you sure you want to restore the patient {name}?",
    "restoreFollowUpMessage": "Are you sure you want to restore the follow up for patient {name}?",
    "restoreAppointmentMessage": "Are you sure you want to restore the appointment for {name}?",
    "restoreBillMessage": "Are you sure you want to restore the bill for {name}?",
    "restoreServiceMessage": "Are you sure you want to restore the service for {name}?",
    "restoreConsentMessage": "Are you sure you want to restore the consent for {name}?",
    "restoreConsultationMessage": "Are you sure you want to restore the consultation {name}?",
    "restoreControlMessage": "Are you sure you want to restore the control {name}?",
    "restoreDiagnosticMessage": "Are you sure you want to restore the diagnostic for {name}?",
    "restoreDoctorMessage": "Are you sure you want to restore the doctor {name}?",
    "restoreMedicationMessage": "Are you sure you want to restore the medication for {name}?",
    "restoreTechniqueMessage": "Are you sure you want to restore the technique for {name}?",
    "restoreUserMessage": "Are you sure you want to restore the user {name}?",
    "restoreRoleMessage": "Are you sure you want to restore the role for {name}?",
    "This action cannot be undone.": "This action cannot be undone.",
    "docOverview": "Documentation Overview",
    "docMessage": "Browse our video tutorials and written guides to get familiar with the app and make your work easier.",
    "All Tutorials": "All Tutorials",
    "Watch": "Regarder",
    "created an appointment": "an appointment",
    "created a bill": "created a bill",
    "created a consent": "created a consent",
    "created a diagnostic": "created a diagnostic",
    "created a doctor": "created a doctor",
    "created a follow up": "created a follow up",
    "created a medication": "created a medication",
    "created a patient": "created a patient",
    "created a service": "created a service",
    "created a technique": "created a technique",
    "created a consultation": "created a consultation",
    "created an intervention": "created an intervention",
    "created a control": "created a control",
    "updated an appointment": "updated an appointment",
    "updated a bill": "updated a bill",
    "updated a consent": "updated a consent",
    "updated a diagnostic": "updated a diagnostic",
    "updated a doctor": "updated a doctor",
    "updated a follow up": "updated a follow up",
    "updated a medication": "updated a medication",
    "updated a patient": "updated a patient",
    "updated a service": "updated a service",
    "updated a technique": "updated a technique",
    "updated a consultation": "updated a consultation",
    "updated an intervention": "updated an intervention",
    "updated a control": "updated a control",
    "deleted an appointment": "deleted an appointment",
    "deleted a bill": "deleted a bill",
    "deleted a consent": "deleted a consent",
    "deleted a diagnostic": "deleted a diagnostic",
    "deleted a doctor": "deleted a doctor",
    "deleted a follow up": "deleted a follow up",
    "deleted a medication": "deleted a medication",
    "deleted a patient": "deleted a patient",
    "deleted a service": "deleted a service",
    "deleted a technique": "deleted a technique",
    "Patient deleted successfully": "Patient deleted successfully",
    "Appointment deleted successfully": "Appointment deleted successfully",
    "Consultation deleted successfully": "Consultation deleted successfully",
    "Intervention deleted successfully": "Intervention deleted successfully",
    "Control deleted successfully": "Control deleted successfully",
    "deleted a consultation": "deleted a consultation",
    "deleted an intervention": "deleted a intervention",
    "deleted a control": "deleted a control",
    "Trash": "Trash",
    "System Administration": "System Administration",
    "Owners": "Owners",
    "Owner List": "Owner List",
    "Edit Owner": "Edit Owner",
    "Licenses": "Licenses",
    "License List": "License List",
    "Key": "Key",
    "Activated On": "Activated On",
    "Activated By": "Activated By",
    "Expires On": "Expires On",
    "Add License": "Add License",
    "Create License": "Create License",
    "Edit License": "Edit License",
    "Sub Specialty": "Sub Specialty",
    "Specialties": "Specialties",
    "Speciality List": "Speciality List",
    "Add Speciality": "Add Speciality",
    "Create Speciality": "Create Speciality",
    "Edit Speciality": "Edit Speciality",
    "Plans": "Plans",
    "Plan List": "Plan List",
    "Add Plan": "Add Plan",
    "Create Plan": "Create Plan",
    "Edit Plan": "Edit Plan",
    "Storage": "Storage",
    "Max Patients": "Max Patients",
    "Max Users": "Max Users",
    "Access": "Access",
    "Versions": "Versions",
    "Version": "Version",
    "Code": "Code",
    "Version List": "Version List",
    "Variable": "Variable",
    "Add Version": "Add Version",
    "Create Version": "Create Version",
    "Edit Version": "Edit Version",
    "Databases": "Databases",
    "Database List": "Database List",
    "Add Database": "Add Database",
    "Create Database": "Create Database",
    "Edit Database": "Edit Database",
    "Demo Requests":"Demo Requests",
    "Demo Request List": "Demo Request List",
    "Add Demo Request": "Add Demo Request",
    "Create Demo Request":"Create Demo Request",
    "Link":"Link",
    "Edit Demo Request": "Edit Demo Request",
    "Completed":"Completed",
    "Not Completed":"Not Completed",
    "Inquiries":"Inquiries",
    "Inquiry List":"Inquiry List",
    "Inquiry not found":"Inquiry not found",
    "Inquiry Details":"Inquiry Details",
    "Mark as Completed":"Mark as Completed",
    "Mark as Not Completed":"Mark as Not Completed",
    "Length": "Length",
    "Null": "Null",
    "Create Table":"Create Table",
    "Edit Table": "Edit Table",
    "Add Column": "Add Column",
    "Table name is required":"Table name is required",
    "Save Changes": "Save Changes",
    "Enter custom default": "Enter custom default",
    "None":"None",
    "Attributes":"Attributes",
    "Constraints":"Constraints",
    "Add Constraint":"Add Constraint",
    "Constraint Type":"Constraint Type",
    "Column":"Column",
    "Reference Table":"Reference Table",
    "Manage Constraints":"Manage Constraints",
    "Primary Key on column":"Primary Key on column",
    "Unique constraint on column":"Unique constraint on column",
    "Foreign Key":"Foreign Key",
    "references":"references",
    "Auto Increment": "Auto Increment",
    "Primary Key": "Primary Key",
    "Tutorials": "Tutorials",
    "Tutorial List": "Tutorial List",
    "Add Tutorial": "Add Tutorial",
    "Create Tutorial": "Create Tutorial",
    "Edit Tutorial": "Edit Tutorial",
    "Thumbnail": "Thumbnail",
    "Video": "Video",
    "Tag": "Tag",
    "Language": "Language",
    "Notification List": "Notification List",
    "Add Notification": "Add Notification",
    "Create Notification": "Create Notification",
    "Edit Notification": "Edit Notification",
    "Personal": "Personal",
    "Tenant": "Tenant",
    "Icon": "Icon",
    "Color": "Color",
    "Arabic Content": "Arabic Content",
    "French Content": "French Content",
    "English Content": "English Content",
    "Database": "Database",
    "UI Elements": "UI Elements",
    "Forms & Tables": "Forms & Tables",
    "Pages": "Pages",
    "Charts & Maps": "Charts & Maps",
    "Others": "Others",
    "Typography": "Typography",
    "Cards": "Cards",
    "Basic": "Basic",
    "Advance": "Advance",
    "Widgets": "Widgets",
    "Components": "Components",
    "Alert": "Alert",
    "Close Alert": "Close Alert",
    "Avatar": "Avatar",
    "Badge": "Badge",
    "Button": "Button",
    "Calendar": "Calendar",
    "Image": "Image",
    "Pagination": "Pagination",
    "Progress Circular": "Progress Circular",
    "Progress Linear": "Progress Linear",
    "Autocomplete": "Autocomplete",
    "Tooltip": "Tooltip",
    "Slider": "Slider",
    "Date Time Picker": "Date Time Picker",
    "Select": "Select",
    "Switch": "Switch",
    "Checkbox": "Checkbox",
    "Radio": "Radio",
    "Textarea": "Textarea",
    "Rating": "Rating",
    "File Input": "File Input",
    "Otp Input": "Otp Input",
    "Form Layout": "Form Layout",
    "Form Validation": "Form Validation",
    "Charts": "Charts",
    "Apex Chart": "Apex Chart",
    "Chartjs": "Chartjs",
    "Account": "Account",
    "Active": "Active",
    "Suspended": "Suspended",
    "Account Settings": "Account Settings",
    "User Profile": "User Profile",
    "FAQ": "FAQ",
    "Dialog Examples": "Dialog Examples",
    "Pricing": "Pricing",
    "List": "List",
    "Edit": "Edit",
    "Nav Levels": "Nav Levels",
    "Level 2.1": "Level 2.1",
    "Level 2.2": "Level 2.2",
    "Level 3.1": "Level 3.1",
    "Level 3.2": "Level 3.2",
    "Raise Support": "Raise Support",
    "Documentation": "Documentation",
    "Dashboards": "Dashboards",
    "Analytics": "Analytics",
    "Apps & Pages": "Apps & Pages",
    "Email": "Email",
    "Chat": "Chat",
    "Invoice": "Invoice",
    "Preview": "Preview",
    "Add": "Add",
    "Update": "Update",
    "User": "User",
    "View": "View",
    "Login v1": "Login v1",
    "Login v2": "Login v2",
    "Login": "Login",
    "Registration": "Registration",
    "Register v1": "Register v1",
    "Register v2": "Register v2",
    "Register": "Register",
    "Forget Password v1": "Forget Password v1",
    "Forget Password v2": "Forget Password v2",
    "Forgot Password v1": "Forgot Password v1",
    "Forgot Password v2": "Forgot Password v2",
    "Forgot Password": "Forgot Password",
    "Reset Password v1": "Reset Password v1",
    "Reset Password v2": "Reset Password v2",
    "Reset Password": "Reset Password",
    "Miscellaneous": "Miscellaneous",
    "Coming Soon": "Coming Soon",
    "This feature is under development and will be available shortly": "This feature is under development and will be available shortly",
    "Thank you for your patience": "Thank you for your patience",
    "Not Authorized": "Not Authorized",
    "Under Maintenance": "Under Maintenance",
    "Error": "Error",
    "Statistics": "Statistics",
    "Blood Groups":"Blood Groups",
    "Monthly Visits":"Monthly Visits",
    "Anesthesiologists":"Anesthesiologists",
    "Year": "Year",
    "Revenu": "Revenu",
    "Monthly Consultations": "Monthly Consultations",
    "Genders": "Genders",
    "Actions": "Actions",
    "Access Control": "Access Control",
    "User Interface": "User Interface",
    "CRM": "CRM",
    "eCommerce": "eCommerce",
    "Icons": "Icons",
    "Chip": "Chip",
    "Dialog": "Dialog",
    "Expansion Panel": "Expansion Panel",
    "Combobox": "Combobox",
    "Textfield": "Textfield",
    "Range Slider": "Range Slider",
    "Menu": "Menu",
    "Utilities": "Utilities",
    "Healthcare Management": "Healthcare Management",
    "Scheduling & Planning": "Scheduling & Planning",
    "Medical Database": "Medical Database",
    "User Management": "User Management",
    "Financial Management": "Financial Management",
    "Communication Tools": "Communication Tools",
    "Reporting Tools": "Reporting Tools",
    "Documentation & Resources": "Documentation & Resources",
    "Snackbar": "Snackbar",
    "Tabs": "Tabs",
    "Home": "Home",
    "Access Denied": "Access Denied",
    "You do not have the necessary permissions to access this page": "You do not have the necessary permissions to access this page",
    "Form Elements": "Form Elements",
    "Form Layouts": "Form Layouts",
    "Authentication": "Authentication",
    "Page Not Found - 404": "Page Not Found - 404",
    "Page Not Found": "Page Not Found",
    "We couldn't find the page you are looking for": "We couldn't find the page you are looking for",
    "500_ERROR_TITLE": "Internal Server Error",
    "500_ERROR_MESSAGE": "The server encountered an internal error or misconfiguration and was unable to complete your request",
    "Not Authorized - 401": "Not Authorized - 401",
    "Server Error - 500": "Server Error - 500",
    "Forms": "Forms",
    "Timeline": "Timeline",
    "Show More":"Show More",
    "Show Less":"Show Less",
    "Disabled Menu": "Disabled Menu",
    "Help Center": "Help Center",
    "Verify Email": "Verify Email",
    "Verify Email v1": "Verify Email v1",
    "Verify Email v2": "Verify Email v2",
    "Two Steps": "Two Steps",
    "Two Steps v1": "Two Steps v1",
    "Two Steps v2": "Two Steps v2",
    "Custom Input": "Custom Input",
    "Extensions": "Extensions",
    "Tour": "Tour",
    "Register Multi-Steps": "Register Multi-Steps",
    "Wizard Examples": "Wizard Examples",
    "Checkout": "Checkout",
    "Create Deal": "Create Deal",
    "Property Listing": "Property Listing",
    "Roles & Permissions": "Roles & Permissions",
    "Roles": "Roles",
    "Role": "Role",
    "Simple Table": "Simple Table",
    "Tables": "Tables",
    "Data Table": "Data Table",
    "Permissions": "Permissions",
    "Permission": "Permission",
    "Apps": "Apps",
    "Misc": "Misc",
    "Wizard Pages": "Wizard Pages",
    "Form Wizard": "Form Wizard",
    "Numbered": "Numbered",
    "ecommerce": "ecommerce",
    "Ecommerce": "Ecommerce",
    "Editors": "Editors",
    "Front Pages": "Front Pages",
    "Landing": "Landing",
    "checkout": "checkout",
    "Payment": "Payment",
    "Swiper": "Swiper",
    "Product": "Product",
    "Category": "Category",
    "Order": "Order",
    "Details": "Details",
    "Customer": "Customer",
    "Manage Review": "Manage Review",
    "Referrals": "Referrals",
    "Settings": "Settings",
    "Overview": "Overview",
    "My Course": "My Course",
    "Course Details": "Course Details",
    "Academy": "Academy",
    "Logistics": "Logistics",
    "Dashboard": "Dashboard",
    "Loading Events": "Loading Events",
    "Loading Appointments": "Loading Appointments",
    "Loading Patients": "Loading Patients",
    "Fleet": "Fleet",
    "Patient": "Patient",
    "Add a new patient": "Add a new patient",
    "Connect Patient": "Connect Patient",
    "Connect": "Connect",
    "Connected": "Connected",
    "Total": "Total",
    "Week": "Week",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Apr",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Aug",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dec",
    "$vuetify": {
        "badge": "Badge",
        "noDataText": "No data available",
        "close": "Close",
        "open": "open",
        "carousel": {
            "ariaLabel": {
                "delimiter": "delimiter"
            }
        },
        "dataFooter": {
            "itemsPerPageText": "Items per page:",
            "itemsPerPageAll": "All",
            "pageText": "{0}-{1} of {2}",
            "firstPage": "First Page",
            "prevPage": "Previous Page",
            "nextPage": "Next Page",
            "lastPage": "Last Page"
        },
        "pagination": {
            "ariaLabel": {
                "root": "root",
                "previous": "previous",
                "next": "next",
                "currentPage": "currentPage",
                "page": "page"
            }
        },
        "input": {
            "clear": "clear",
            "appendAction": "appendAction",
            "prependAction": "prependAction",
            "counterSize": "counterSize",
            "otp": "otp"
        },
        "fileInput": {
            "counterSize": "counterSize"
        },
        "rating": {
            "ariaLabel": {
                "item": "item"
            }
        },"datePicker": {
            "title": "Title",
            "header":"Header"
        }
    }
}